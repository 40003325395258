import { computed, ref, watch } from '@vue/composition-api'

import store from '@/store'

import { convertISODateTime } from '@core/utils/filter'

import useToast from '@useToast'

export default function useAccountsPayableHandle() {
  const { toastSuccess, toastError } = useToast()

  const refAccountPayableListTable = ref(null)
  const refAccountPayableDetailTable = ref(null)

  const tableListColumns = [
    { label: 'no', key: 'no' },
    // { label: 'checkbox', key: 'checkbox' },
    { label: 'agCode', key: 'agCode' },
    { label: 'agName', key: 'agName' },
    { label: 'openingBalance', key: 'openingBalance' },
    { label: 'receivables', key: 'receivables' },
    // { label: 'tradeCreditors', key: 'tradeCreditors' },
    { label: 'paid', key: 'paid' },
    { label: 'closingBalance', key: 'closingBalance' },
    // { label: 'action', key: 'action' },
  ]

  const tableDetailColumns = [
    { label: 'no', key: 'no' },
    { label: 'airline', key: 'airline' },
    { label: 'source', key: 'source' },
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'paxName', key: 'paxName' },
    { label: 'paxType', key: 'paxType' },
    // { label: 'paxId', key: 'paxId' },
    { label: 'ticketNumber', key: 'ticketNumber' },
    { label: 'paymentCode', key: 'paymentCode' },
    { label: 'ticketType', key: 'ticketType' },
    { label: 'flightType', key: 'flightType' },
    { label: 'trips', key: 'trips' },
    { label: 'flightDates', key: 'flightDates' },
    { label: 'bookingClass', key: 'bookingClass' },
    { label: 'fareBasisCode', key: 'fareBasisCode' },
    { label: 'bookingDate', key: 'bookingDate' },
    { label: 'issueDate', key: 'issueDate' },
    { label: 'booker', key: 'booker' },
    { label: 'issuer', key: 'issuer' },
    { label: 'agency', key: 'agency' },
    { label: 'signBook', key: 'signBook' },
    { label: 'currency', key: 'currency' },
    { label: 'priceTicket', key: 'priceTicket' },
    { label: 'vat', key: 'tax' },
    { label: 'otherTax', key: 'otherTax' },
    { label: 'tax', key: 'collectionFee' },
    // { label: 'fee', key: 'fee' },
    { label: 'feeIn', key: 'feeIn' },
    { label: 'feeOut', key: 'feeOut' },
    { label: 'feeService', key: 'feeService' },
    // { label: 'commissionPrepaid', key: 'commissionPrepaid' },
    { label: 'commissionPrepaidIn', key: 'commissionPrepaidIn' },
    { label: 'commissionPrepaidOut', key: 'commissionPrepaidOut' },
    { label: 'unpaidCommission', key: 'unpaidCommission' },
    { label: 'commission', key: 'commission' },
    // { label: 'totalPayment', key: 'totalPayment' },
    { label: 'tradeCreditors', key: 'tradeCreditors' },
    { label: 'receivables', key: 'receivables' },
    { label: 'profit', key: 'profit' },
    // { label: 'accountsPayable', key: 'accountsPayable' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    // { label: 'actions', key: 'actions' },
  ]

  const sizePerPage = ref(10)
  const totalAccountPayable = ref(0)
  const currentPage = ref(1)

  // Loading
  const loading = ref(null)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const customerCodeFilter = ref(null) // Agency/Customer Code

  // ANCHOR Filter list
  const fromDateFilter = ref(convertISODateTime(new Date()).dateFilter)
  const toDateFilter = ref(convertISODateTime(new Date()).dateFilter)
  const agencyCodeListFilter = ref(null)
  const customerCodeListFilter = ref(null)

  let filtersParamsDetail = {}

  const totalOpeningBalance = ref(null)
  const totalClosingBalance = ref(null)
  const totalTradeCreditor = ref(null)
  const totalReceivables = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAccountPayableListTable.value
      ? refAccountPayableListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAccountPayable.value,
    }
  })

  const refetchData = () => {
    refAccountPayableListTable.value.refresh()
  }

  function refetchDataDetail($event = {}) {
    filtersParamsDetail = { ...$event }
    refAccountPayableDetailTable.value.refresh()
  }

  const clearFilter = () => {
    // customerCodeFilter.value = null
    fromDateFilter.value = convertISODateTime(new Date()).dateFilter
    toDateFilter.value = convertISODateTime(new Date()).dateFilter
    customerCodeListFilter.value = null
    agencyCodeListFilter.value = null

    refetchData()
  }

  // Empty filter
  const isEmptyFilter = computed(
    () => customerCodeListFilter.value === null
    && agencyCodeListFilter.value === null
    && toDateFilter.value === convertISODateTime(new Date()).dateFilter
    && fromDateFilter.value === convertISODateTime(new Date()).dateFilter,
  )

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )

  const fetchAccountPayable = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-account-payable/fetchAccountPayable', {
        startDate: fromDateFilter.value,
        endDate: toDateFilter.value,
        agencyCode: agencyCodeListFilter.value,
        customerCode: customerCodeListFilter.value,
      })
      .then(response => {
        totalAccountPayable.value = response.data.total
        const { data } = response
        totalTradeCreditor.value = data.totalTradeCreditor
        totalReceivables.value = data.totalReceivables
        totalOpeningBalance.value = data.totalOpeningBalance
        totalClosingBalance.value = data.totalClosingBalance
        callback(data.items)
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.fetchListError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchAccountPayableDetail = () => {
    loading.value = true

    if (
      typeof filtersParamsDetail === 'object'
      && Object.keys(filtersParamsDetail).length === 0
    ) {
      filtersParamsDetail = {
        startDate: convertISODateTime(new Date()).dateFilter,
        endDate: convertISODateTime(new Date()).dateFilter,
      }
    }

    store
      .dispatch('app-account-payable/accountPayableDetail', {
        ...filtersParamsDetail,
      })
      .then(response => {
        totalAccountPayable.value = response.data.total
        const { data } = response
        console.log({ data })
        totalTradeCreditor.value = data.totalTradeCreditor
        totalReceivables.value = data.totalReceivables
        totalOpeningBalance.value = data.totalOpeningBalance
        totalClosingBalance.value = data.totalClosingBalance
        // callback(data.items)
        loading.value = false
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.fetchDetailError',
        })
        loading.value = false
      })
  }

  const updateSaleReport = saleReports => {
    store
      .dispatch('app-account-payable/updateSaleReport', { saleReports })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.updateSaleReportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.updateSaleReportError',
        })
      })
  }

  const getDate = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-account-payable/getDate')
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: e,
        })
        reject(e)
      })
  })

  const bookClosing = date => {
    store
      .dispatch('app-account-payable/bookClosing', { date })
      .then(() => {})
      .then(() => {})
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.bookClosingSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.bookClosingError',
        })
      })
  }

  const exportAccountPayable = () => {
    store
      .dispatch('app-account-payable/exportAccountPayable', {
        startDate: fromDateFilter.value,
        endDate: toDateFilter.value,
        agencyCode: agencyCodeListFilter.value,
        customerCode: customerCodeListFilter.value,
      })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.exportError',
        })
      })
  }

  const exportAccountPayableDetail = filtersParamsDetail => {
    store
      .dispatch(
        'app-account-payable/exportAccountPayableDetail',
        filtersParamsDetail,
      )
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.exportError',
        })
      })
  }

  const exportSaleReportTemplate = () => {
    store
      .dispatch('app-account-payable/exportSaleReportTemplate', {
        language: 'vi',
      })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.exportError',
        })
      })
  }

  // RETAIL
  const fetchRetailAccountPayableList = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-account-payable/fetchRetailAccountPayableList', {
        startDate: fromDateFilter.value,
        endDate: toDateFilter.value,
        agencyCode: agencyCodeListFilter.value,
        customerCode: customerCodeListFilter.value,
      })
      .then(response => {
        totalAccountPayable.value = response.data.total
        const { data } = response
        totalTradeCreditor.value = data.totalTradeCreditor
        totalReceivables.value = data.totalReceivables
        totalOpeningBalance.value = data.totalOpeningBalance
        totalClosingBalance.value = data.totalClosingBalance
        callback(data.items)
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.fetchListError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const getRetailAccountPayableDetail = () => {
    loading.value = true

    if (
      typeof filtersParamsDetail === 'object'
      && Object.keys(filtersParamsDetail).length === 0
    ) {
      filtersParamsDetail = {
        startDate: convertISODateTime(new Date()).dateFilter,
        endDate: convertISODateTime(new Date()).dateFilter,
      }
    }

    store
      .dispatch('app-account-payable/getRetailAccountPayableDetail', {
        ...filtersParamsDetail,
      })
      .then(response => {
        totalAccountPayable.value = response.data.total
        const { data } = response
        console.log({ data })
        totalTradeCreditor.value = data.totalTradeCreditor
        totalReceivables.value = data.totalReceivables
        totalOpeningBalance.value = data.totalOpeningBalance
        totalClosingBalance.value = data.totalClosingBalance
        // callback(data.items)
        loading.value = false
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.fetchDetailError',
        })
        loading.value = false
      })
  }

  return {
    fetchAccountPayable,
    fetchAccountPayableDetail,
    updateSaleReport,
    getDate,
    bookClosing,

    tableListColumns,
    tableDetailColumns,
    sizePerPage,
    currentPage,
    totalAccountPayable,
    dataMeta,

    sortBy,
    isSortDirDesc,

    // Refs
    refAccountPayableListTable,
    refAccountPayableDetailTable,

    refetchData,
    refetchDataDetail,
    clearFilter,

    // Extra Filters
    customerCodeFilter,
    agencyCodeListFilter,
    customerCodeListFilter,
    fromDateFilter,
    toDateFilter,
    isEmptyFilter,
    totalOpeningBalance,
    totalClosingBalance,
    totalTradeCreditor,
    totalReceivables,

    // Loading
    loading,

    exportAccountPayable,
    exportAccountPayableDetail,
    exportSaleReportTemplate,

    fetchRetailAccountPayableList,
    getRetailAccountPayableDetail,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-modal-agency-change-password-employee-account",
      "title": _vm.$t('agency.employees.changePassword'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "p-1",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient px-1 px-md-2",
          attrs: {
            "pill": "",
            "disabled": !_vm.passwordState
          },
          on: {
            "click": function click($event) {
              return _vm.handleConfirmChangePassword();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirm')) + " ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.employees.changePasswordTitle')) + " ")]), _vm.employees ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', [_c('BFormInput', {
    staticClass: "mt-75",
    attrs: {
      "id": "input-password",
      "state": _vm.passwordState,
      "aria-describedby": "input-password-feedback",
      "placeholder": _vm.$t('flight.placeholderInput')
    },
    model: {
      value: _vm.newPassword,
      callback: function callback($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-password-feedback"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.employees.min6character')) + " ")])], 1), _c('div', {
    staticClass: "text-center mt-50"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-warning rounded-lg",
      "size": "sm"
    },
    on: {
      "click": _vm.handleGenerateNewPassword
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "RepeatIcon"
    }
  }), _vm._v(" Tạo ngẫu nhiên ")], 1)], 1)]) : _c('div', [_c('b-spinner', {
    attrs: {
      "variant": "info"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
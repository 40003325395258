<template>
  <b-modal
    id="id-topup-modal"
    size="lg"
    centered
    header-bg-variant="light-info"
    :title="$t('agency.topup')"
    :ok-title="$t('agency.generateQRcode')"
    :cancel-title="$t('cancel')"
    :ok-disabled="!!qrCodeImgUrl"
    @show="$emit('show', $event)"
    @ok="handleGenerateQRcode"
  >
    <IAmOverlay :loading="isEmpty(recipientBank)">
      <validation-observer ref="refFormObserver">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('agency.bankName')">
                <v-select
                  v-model="recipientBank"
                  label="bankAccountNo"
                  :options="recipientBanksList"
                  :clearable="false"
                  @input="handleChangeInput"
                >
                  <template #selected-option="data">
                    {{ `${data.bankAccountName} - ${data.bankAccountNo} - ${data.bankCode} - ${data.branch}` }}
                  </template>
                  <template #option="data">
                    {{ `${data.bankAccountName} - ${data.bankAccountNo} - ${data.bankCode} - ${data.branch}` }}
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('agency.accNumber')">
                <b-form-input
                  :value="recipientAccNumber"
                  readonly
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group :label="$t('agency.accHolder')">
                <b-form-input
                  :value="recipientAgencyName"
                  readonly
                />
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group :label="$t('agency.accHolder')">
                <b-form-input
                  :value="recipientBankAccountName"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('agency.branch')">
                <b-form-input
                  :value="recipientBankBranch"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-if="amountNumber"
                :label="$t('agency.amountNumber')"
              >
                <b-form-input
                  :value="formatCurrency(amountNumber)"
                  readonly
                />
              </b-form-group>
              <b-form-group v-else>
                <template #label>
                  {{ $t('agency.amountNumber') }}
                  <span class="text-danger">(*)</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :rules="{
                    required: true,
                    max: MAX_LENGTH.paymentAmount,
                  }"
                  :name="$t('agency.amountNumber')"
                >
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      :name="$t('agency.amountNumber')"
                      :value="paymentAmount"
                      :state="errors[0] ? false : null"
                      :placeholder="$t('agency.amountNumber')"
                      class="d-none"
                    />
                    <IAmInputMoney
                      :value-money.sync="paymentAmount"
                      class="flex-grow-1"
                    />
                    <template #append>
                      <b-input-group-text :class="{ 'border-danger': errors[0] }">
                        {{ $t('agency.currency') }}
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group>
                <template #label>
                  {{ $t('agency.transferContent') }}
                  <span class="text-danger">(*)</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :rules="{
                    required: true,
                    max: MAX_LENGTH.transferContent,
                  }"
                  :name="$t('agency.transferContent')"
                >
                  <b-input-group class="input-group-merge">
                    <template #prepend>
                      <b-input-group-text :class="{ 'border-danger': errors[0] }">
                        {{ senderAgencyCode }}
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model.trim="transferContent"
                      :state="errors[0] ? false : null"
                      :placeholder="$t('agency.transferContent')"
                      @update="handleChangeInput"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row>
            <b-img
              v-if="qrCodeImgUrl"
              :src="qrCodeImgUrl"
              fluid
              center
              alt="QR Code"
              style="max-height: 400px"
            />
          </b-row>
        </b-form>
      </validation-observer>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, computed, watch, onUnmounted,
} from '@vue/composition-api'
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import isEmpty from 'lodash/isEmpty'

import IAmOverlay from '@/components/IAmOverlay.vue'
import IAmInputMoney from '@/components/IAmInputMoney.vue'
import {
  TOPUP_APP_STORE_MODULE_NAME as TOPUP_STORE,
  MAX_LENGTH,
} from '@/constants/topup'
import store from '@/store'

import { formatCurrency } from '@core/utils/filter'

import topupStoreModule from '@agencies/topupStoreModule'
import useTopupHandle from '@agencies/useTopupHandle'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BImg,
    vSelect,
    IAmOverlay,
    IAmInputMoney,
  },

  props: {
    sender: {
      type: Object,
      required: true,
    },
    recipient: {
      type: Object,
      required: true,
    },
    receivables: {
      type: [Number, String],
      default: '',
    },
  },

  setup(props) {
    // Register module
    if (!store.hasModule(TOPUP_STORE)) store.registerModule(TOPUP_STORE, topupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOPUP_STORE)) store.unregisterModule(TOPUP_STORE)
    })

    const paymentAmount = ref(null)
    const transferContent = ref('')
    const recipientBank = ref({})

    const {
      // fetchBanks,
      fetchTopupQrCode,
      // confirmTopup,
    } = useTopupHandle()

    // computed
    const senderAgencyCode = computed(() => props.sender?.agencyCode || '')
    const recipientAgencyName = computed(() => props.recipient?.agencyName || '')
    const recipientBanksList = computed(() => props.recipient?.bankAccounts?.filter(item => item) || [])
    const recipientBanksListTotal = computed(() => (props.recipient?.bankAccounts || []).length)
    const recipientAccNumber = computed(() => recipientBank.value?.bankAccountNo)
    const recipientBankAccountName = computed(() => recipientBank.value?.bankAccountName)
    const recipientBankBranch = computed(() => recipientBank.value?.branch)
    const transferCodeAndContent = computed(
      () => `${senderAgencyCode.value} ${transferContent.value}`,
    )
    const qrCodeImgUrl = computed(() => store.state[TOPUP_STORE]?.topupQrCode || '')
    const amountNumber = computed(() => props?.receivables || '')

    // methods
    function handleGenerateQRcode(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          // fetchBanks() // for testing and using later
          this.$bvModal.show('modal-api-loading')
          fetchTopupQrCode({
            senderId: props.sender.id,
            senderType: props.sender.type,
            recipientId: props.recipient.id,
            paymentAmount: Number(paymentAmount.value) || amountNumber.value,
            bankCode: recipientBank.value.bankCode,
            bankAccountNo: recipientBank.value.bankAccountNo,
            bankAccountName: recipientBank.value.bankAccountName,
          }).finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
          // for testing and using later
          // confirmTopup({
          //   description: transferCodeAndContent.value,
          //   paymentAmount: Number(paymentAmount.value),
          // })
        }
      })
    }

    /** use asyn/await, pass testing
    async function handleGenerateQRcode(bvModalEvent) {
      bvModalEvent.preventDefault()
      const success = await this.$refs.refFormObserver.validate()
      if (success) {
        // await fetchBanks() // for testing and using later
        await fetchTopupQrCode({
          senderId: props.sender.id,
          senderType: props.sender.type,
          recipientId: props.recipient.id,
          paymentAmount: Number(paymentAmount.value),
          bankCode: recipientBank.value.bankCode,
          bankAccountNo: recipientBank.value.bankAccountNo,
          bankAccountName: recipientBank.value.bankAccountName,
        })
        // for testing and using later
        // await confirmTopup({
        //   description: transferCodeAndContent.value,
        //   paymentAmount: Number(paymentAmount.value),
        // })
      }
    }
    */

    function handleChangeInput() {
      if (qrCodeImgUrl.value) {
        store.dispatch(`${TOPUP_STORE}/removeTopupQrCode`)
      }
    }

    watch(recipientBanksListTotal, newVal => {
      if (newVal) {
        recipientBank.value = recipientBanksList.value[0]
      }
    }, { immediate: true })

    watch(paymentAmount, () => {
      handleChangeInput()
    }, { immediate: true })

    return {
      formatCurrency,
      // constant
      MAX_LENGTH,

      // data
      paymentAmount,
      transferContent,
      qrCodeImgUrl,
      recipientBank,

      // computed
      senderAgencyCode,
      recipientAgencyName,
      recipientBanksList,
      recipientAccNumber,
      recipientBankAccountName,
      recipientBankBranch,
      transferCodeAndContent,
      amountNumber,

      // methods
      handleGenerateQRcode,
      handleChangeInput,

      isEmpty,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

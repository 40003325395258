<template>
  <b-modal
    id="modal-disable-draft"
    :title="$t('reservation.confirm')"
    title-class="font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    @show="onShow"
  >
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end w-100">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center rounded-pill mr-2"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="!iAgree"
          @click="handleSubmit"
        >
          <div class="d-flex-center px-25">
            Xác nhận
          </div>
        </b-button>
      </div>
    </template>

    <div>
      Tắt tính năng này đi, AG <b style="color: red">phải cam kết tự chịu trách nhiệm</b> nếu xảy ra các trường hợp vi phạm quy định đặt chỗ của hãng,
      {{ mamaShortName }} sẽ tiến hành xử lý theo đúng quy định.
    </div>

    <!-- ANCHOR - iAgree checkbox -->
    <b-form-checkbox
      v-model="iAgree"
      name="i-agree"
      class="mt-50"
    >
      <div class="text-body fw-600 font-medium-1">
        {{ $t('reservation.iAgree') }}
      </div>
    </b-form-checkbox>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { env } from '@/libs/env'

export default {
  components: {
    BModal,
    BButton,
    BFormCheckbox,
  },
  props: {
    agencyToEdit: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const iAgree = ref(false)

    function handleSubmit() {
      emit('update-enable-draft', false)
      this.$bvModal.hide('modal-disable-draft')
    }
    function onShow() {
      iAgree.value = false
    }

    const mamaShortName = env.mamaShortName
    return {
      handleSubmit,
      iAgree,
      onShow,
      mamaShortName,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

import { getCurrentInstance } from '@vue/composition-api'

// by convention, composable function names start with "use"
export default function useToast() {
  const root = getCurrentInstance().root.proxy

  function toast(option) {
    root.toast(option)
  }

  function toastSuccess(option) {
    root.toastSuccess(option)
  }

  function toastError(option) {
    root.toastError(option)
  }
  function toastWarning(option) {
    root.toastWarning(option)
  }
  return {
    toast, toastSuccess, toastError, toastWarning,
  }
}

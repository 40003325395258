/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable func-names */
// Tệp plugin js
/**
 * Flatpickr Year Select Plugin.
 * https://stackoverflow.com/a/56298283
 * @author Labi Romabravo
 */

/**
 * Hàm YearDropdownPlugin là hàm chính tạo ra Flatpickr Year Select Plugin.
 * @param {Object} pluginConfig - Đối tượng cấu hình cho plugin.
 * @returns {Function} - Hàm được sử dụng như một plugin cho Flatpickr.
 */
const YearDropdownPlugin = function (pluginConfig) {
  // Cấu hình mặc định cho plugin
  const defaultConfig = {
    text: '',
    theme: 'light',
    date: new Date(),
    yearStart: 100,
    yearEnd: 2,
  }

  // Kết hợp cấu hình mặc định với pluginConfig được cung cấp (nếu có)
  const config = {}
  for (const key in defaultConfig) {
    config[key] = pluginConfig && pluginConfig[key] !== undefined ? pluginConfig[key] : defaultConfig[key]
  }

  /**
   * Hàm tiện ích để trích xuất năm từ một chuỗi ngày.
   * @param {string} value - Chuỗi ngày trong định dạng 'MM/DD/YYYY'.
   * @returns {number} - Năm được trích xuất.
   */
  const getYear = function (value) {
    const date = value.split('/')
    return parseInt(date[2], 10)
  }

  // Năm hiện tại và năm đã chọn dựa trên ngày được cung cấp
  const currYear = new Date().getFullYear()
  const selectedYear = getYear(config.date.toLocaleDateString())

  // Tạo một phần tử 'select' cho dropdown năm
  const yearDropdown = document.createElement('select')

  /**
   * Tạo các phần tử 'option' cho dropdown năm trong phạm vi được chỉ định.
   * @param {number} year - Năm đã chọn.
   */
  const createSelectElement = function (year) {
    const start = new Date().getFullYear() - config.yearStart
    const end = currYear + config.yearEnd

    for (let i = end; i >= start; i--) {
      const option = document.createElement('option')
      option.value = i
      option.text = i
      yearDropdown.appendChild(option)
    }
    yearDropdown.value = selectedYear
  }

  // Trả về một hàm để sử dụng như một plugin cho Flatpickr
  return function (fp) {
    // Tạo một container cho dropdown năm
    fp.yearSelectContainer = fp._createElement('div', `flatpickr-year-select ${config.theme}Theme`, config.text)

    // Container này có thể tập trung được
    fp.yearSelectContainer.tabIndex = -1

    // Tạo phần tử 'select' cho dropdown năm
    createSelectElement(selectedYear)

    // Thêm một người nghe sự kiện để xử lý sự thay đổi trong dropdown năm
    yearDropdown.addEventListener('change', evt => {
      const year = evt.target.options[evt.target.selectedIndex].value
      fp.changeYear(Number(year))
    })

    // Nối thêm dropdown năm vào container
    fp.yearSelectContainer.append(yearDropdown)

    // Trả về một đối tượng với người nghe sự kiện
    return {
      // onReady được gọi khi Flatpickr đã sẵn sàng
      onReady: function onReady() {
        // Tìm tên lớp được sử dụng cho điều hướng tháng
        const name = fp.monthNav.className
        const yearInputCollection = fp.calendarContainer.getElementsByClassName(name)
        // console.log({ fp, name, yearInputCollection })
        const el = yearInputCollection[0]
        // Chèn dropdown năm vào giao diện người dùng Flatpickr
        el.parentNode.insertBefore(fp.yearSelectContainer, el.parentNode.firstChild)
        // el.parentNode.appendChild(fp.yearSelectContainer)
      },
      // onMonthChange được gọi khi tháng thay đổi
      onMonthChange: (...value) => {
        const year = value[2].currentYear
        // Cập nhật năm đã chọn trong dropdown khi tháng thay đổi
        yearDropdown.value = year
      },
    }
  }
}

// Xuất YearDropdownPlugin làm export mặc định của module
export default YearDropdownPlugin

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-disable-draft",
      "title": _vm.$t('reservation.confirm'),
      "title-class": "font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "md"
    },
    on: {
      "show": _vm.onShow
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex justify-content-end w-100"
        }, [_c('b-button', {
          staticClass: "center rounded-pill mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0",
          attrs: {
            "pill": "",
            "disabled": !_vm.iAgree
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" Xác nhận ")])])], 1)];
      }
    }])
  }, [_c('div', [_vm._v(" Tắt tính năng này đi, AG "), _c('b', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("phải cam kết tự chịu trách nhiệm")]), _vm._v(" nếu xảy ra các trường hợp vi phạm quy định đặt chỗ của hãng, " + _vm._s(_vm.mamaShortName) + " sẽ tiến hành xử lý theo đúng quy định. ")]), _c('b-form-checkbox', {
    staticClass: "mt-50",
    attrs: {
      "name": "i-agree"
    },
    model: {
      value: _vm.iAgree,
      callback: function callback($$v) {
        _vm.iAgree = $$v;
      },
      expression: "iAgree"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.iAgree')) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "border mb-0 mt-1",
    attrs: {
      "id": "agencies-edit-company",
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.company.headerInfo')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "p-1"
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || false
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var pristine = _ref.pristine;
        return [!_vm.haveCompany ? _c('BAlert', {
          staticClass: "p-1",
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.company.noCompany')) + " ")]) : _vm._e(), _c('b-row', _vm._l(_vm.fields, function (_ref2) {
          var key = _ref2.key,
            isRequired = _ref2.required,
            isDisabled = _ref2.disabled,
            cannotInput = _ref2.cannotInput,
            formatterInput = _ref2.formatterInput,
            validate = _ref2.validate;
          return _c('b-col', {
            key: key,
            class: ['updatedAt', 'createdAt'].includes(key) ? 'order-1' : '',
            attrs: {
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t("agency.company.field.".concat(key)),
              "vid": key,
              "rules": {
                required: isRequired,
                email: validate === 'email'
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var errors = _ref3.errors;
                return [_c('b-form-group', [_c('div', {
                  staticClass: "d-flex gap-1"
                }, [_c('label', {
                  attrs: {
                    "for": key
                  }
                }, [_vm._v(_vm._s(_vm.$t("agency.company.field.".concat(key))))]), isRequired ? _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v("(*)")]) : _vm._e()]), cannotInput ? _c('div', [_vm._v(" " + _vm._s(formatterInput ? formatterInput(_vm.agencyCompanyData[key]) : _vm.agencyCompanyData[key]) + " ")]) : key === 'generateInvoiceNumberOnCreation' ? _c('div', [_c('b-form-radio-group', {
                  staticClass: "text-dark",
                  attrs: {
                    "id": "radio-group-1",
                    "disabled": _vm.isDisabledInput || isDisabled,
                    "options": [{
                      text: _vm.$t('agency.company.field.onCreation'),
                      value: true
                    }, {
                      text: _vm.$t('agency.company.field.onRelease'),
                      value: false
                    }],
                    "name": _vm.$t("agency.company.field.".concat(key))
                  },
                  model: {
                    value: _vm.agencyCompanyData[key],
                    callback: function callback($$v) {
                      _vm.$set(_vm.agencyCompanyData, key, $$v);
                    },
                    expression: "agencyCompanyData[key]"
                  }
                })], 1) : _c('b-form-input', {
                  directives: [{
                    name: "remove-non-numeric-chars",
                    rawName: "v-remove-non-numeric-chars.allNumber",
                    value: validate === 'number',
                    expression: "validate === 'number'",
                    modifiers: {
                      "allNumber": true
                    }
                  }],
                  staticClass: "form-control",
                  attrs: {
                    "id": key,
                    "state": errors.length > 0 ? false : null,
                    "disabled": _vm.isDisabledInput || isDisabled,
                    "name": _vm.$t("agency.company.field.".concat(key)),
                    "placeholder": _vm.$t("agency.company.ph.".concat(key))
                  },
                  model: {
                    value: _vm.agencyCompanyData[key],
                    callback: function callback($$v) {
                      _vm.$set(_vm.agencyCompanyData, key, $$v);
                    },
                    expression: "agencyCompanyData[key]"
                  }
                }), _c('BFormInvalidFeedback', {
                  staticClass: "text-danger"
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1);
        }), 1), _c('b-row', [_c('b-col', {
          staticClass: "d-flex gap-1 order-2",
          attrs: {
            "cols": "6"
          }
        }, [!_vm.haveCompany ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.createHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('create')) + " ")]) : _vm._e(), _vm.haveCompany && _vm.isEdit ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": _vm.updateHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('saveChanges')) + " ")]) : _vm._e(), _vm.isDisabledInput ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              _vm.isEdit = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('edit')) + " ")]) : _vm._e(), _vm.haveCompany && _vm.isEdit ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.confirmCancelEdit(pristine);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('cancelEdit')) + " ")]) : _vm._e()], 1), _c('b-col', {
          staticClass: "d-flex justify-content-end gap-1 order-2",
          attrs: {
            "cols": "6"
          }
        }, [_vm.haveCompany ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": _vm.agencyCompany.active ? 'danger' : 'info',
            "disabled": !_vm.agencyCompany.active && !_vm.isRoleF1
          },
          on: {
            "click": _vm.toggleActiveCompanyHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.agencyCompany.active ? _vm.$t('agency.company.deactiveCompany') : _vm.$t('agency.company.activeCompany')) + " ")]) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { apiTopup } from '@/api/'

export default {
  namespaced: true,

  state: {
    banks: [],
    topupQrCode: '',
  },

  mutations: {
    SET_BANKS(state, data) {
      state.banks = data
    },

    SET_TOPUP_QRCODE(state, data) {
      state.topupQrCode = data
    },
  },

  actions: {
    async fetchBanks({ commit }) {
      const res = await apiTopup.fetchBanks()
      commit('SET_BANKS', res?.data || [])

      return res
    },

    async fetchTopupQrCode({ commit }, payload = {}) {
      const res = await apiTopup.fetchTopupQrCode(payload)
      commit('SET_TOPUP_QRCODE', res?.qrCode || '')

      return res
    },

    removeTopupQrCode({ commit }) {
      commit('SET_TOPUP_QRCODE', '')
    },

    async confirmTopup(_ctx, payload = {}) {
      const res = apiTopup.confirmTopup(payload)
      return res
    },
  },
}

<template lang="">
  <div>
    <IAmOverlay :loading="!!loading || !agencyWebConfigData || loadingUpload">
      <validation-observer
        ref="refFormObserver"
        #default="{ pristine }"
      >
        <BAlert
          v-if="!haveWebConfig"
          show
          variant="warning"
          class="p-1"
        >
          {{ $t('agency.webConfig.noWebConfig') }}
        </BAlert>

        <!-- SECTION Web Config -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mb-0 mt-1"
          no-body
        >
          <template #header>
            <div class="d-flex align-items-center justify-content-between w-100">
              <h5 class="m-0">
                {{ $t('agency.webConfig.title') }}
              </h5>
            </div>
          </template>
          <b-card-body class="p-1">
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.websiteUrl`)"
                  vid="websiteUrl"
                  rules="required|url"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="websiteUrl">{{ $t(`agency.webConfig.field.websiteUrl`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>
                    <b-form-input
                      id="websiteUrl"
                      v-model="agencyWebConfigData.websiteUrl"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isDisabledInput || (meData?.agency?.id == agencyId && !isRoleF1)"
                      :name="$t(`agency.webConfig.field.websiteUrl`)"
                      :placeholder="$t(`agency.webConfig.ph.websiteUrl`)"
                      @input="val=>agencyWebConfigData.websiteUrl = val.replace(/.*\/\/(www\.)?/, '').replace(/\/.*/, '')"
                    />
                    <small class="text-danger">
                      (*) Vui lòng chỉ điền tên miền, không bao gồm [HTTPS] và kí tự đặc biệt [/&?...]. Ví dụ: vemaybay.one
                    </small>
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.useSeparateATS`)"
                  vid="useSeparateATS"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="useSeparateATS">{{ $t(`agency.webConfig.field.useSeparateATS`) }}</label>
                    </div>
                    <b-form-checkbox
                      id="useSeparateATS"
                      v-model="agencyWebConfigData.useSeparateATS"
                      switch
                      :disabled="isDisabledInput || (meData?.agency?.id == agencyId && !isRoleF1)"
                      class="py-50"
                    />
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR: tên trang -->
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.title`)"
                  vid="title"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="title">{{ $t(`agency.webConfig.field.title`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>
                    <b-input-group class="input-group custom-file-button">
                      <b-form-input
                        id="title"
                        v-model="agencyWebConfigData.title"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isDisabledInput"
                        :name="$t(`agency.webConfig.field.title`)"
                        :placeholder="$t(`agency.webConfig.ph.title`)"
                      />
                    </b-input-group>
                    <BFormInvalidFeedback
                      v-if="errors[0]"
                      class="text-danger"
                      style="display: block !important"
                    >
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR: Logo -->
              <b-col md="6">
                <validation-provider
                  #default="{ valid, errors }"
                  :name="$t(`agency.webConfig.field.logo`)"
                  vid="logo"
                  rules="url"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="logo">{{ $t(`agency.webConfig.field.logo`) }}</label>
                    </div>
                    <b-input-group class="input-group custom-file-button">
                      <b-form-input
                        id="logo"
                        v-model="agencyWebConfigData.configs.logo"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isDisabledInput"
                        :name="$t(`agency.webConfig.field.logo`)"
                        :placeholder="$t(`agency.webConfig.ph.logo`)"
                      />
                      <template
                        v-if="!isDisabledInput"
                        #append
                      >
                        <label
                          for="logo-file"
                          class="btn btn-warning px-25 mb-0"
                        >
                          {{ $t('agency.uploadImage') }}
                        </label>
                      </template>
                    </b-input-group>

                    <small class="text-danger">
                      (*) Ảnh logo có tỉ lệ 2:1. Ví dụ: 200x100, 300x150 (px)
                    </small>
                    <BFormInvalidFeedback
                      v-if="errors[0]"
                      class="text-danger"
                      style="display: block !important"
                    >
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                  <b-form-file
                    id="logo-file"
                    ref="logoRef"
                    v-model="logoFile"
                    accept=".jpg, .png"
                    :disabled="isDisabledInput"
                    plain
                    class="d-none"
                    @input="inputImageLogoFormData"
                  />
                  <div class="d-flex align-items-center">
                    <b-img-lazy
                      v-if="valid && agencyWebConfigData.configs.logo"
                      :src="agencyWebConfigData.configs.logo"
                      center
                      class="p-50 border mt-2"
                      style="max-height: 30%; max-width: 40%;"
                      :alt="$t('imgNotFound')"
                    />
                  </div>
                </validation-provider>
              </b-col>

              <!-- ANCHOR: banner -->
              <b-col md="6">
                <validation-provider
                  #default="{ valid, errors }"
                  :name="$t(`agency.webConfig.field.banner`)"
                  vid="banner"
                  rules="url"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="banner">{{ $t(`agency.webConfig.field.banner`) }}</label>
                    </div>
                    <b-input-group class="input-group custom-file-button">
                      <b-form-input
                        id="banner"
                        v-model="agencyWebConfigData.configs.banner"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isDisabledInput"
                        :name="$t(`agency.webConfig.field.banner`)"
                        :placeholder="$t(`agency.webConfig.ph.banner`)"
                      />

                      <template
                        v-if="!isDisabledInput"
                        #append
                      >
                        <label
                          for="banner-file"
                          class="btn btn-warning px-25 mb-0"
                        >
                          {{ $t('agency.uploadImage') }}
                        </label>
                      </template>
                    </b-input-group>

                    <small class="text-danger">
                      (*) Ảnh banner có tỉ lệ 16:9, ví dụ: 1920x1080 (px). Kích thước ảnh nhỏ hơn 1MB
                    </small>
                    <BFormInvalidFeedback
                      v-if="errors[0]"
                      class="text-danger"
                      style="display: block !important"
                    >
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                  <b-form-file
                    id="banner-file"
                    ref="bannerRef"
                    v-model="bannerFile"
                    accept=".jpg, .png"
                    :disabled="isDisabledInput"
                    plain
                    class="d-none"
                    @input="inputImageBannerFormData(1)"
                  />
                  <b-img-lazy
                    v-if="valid && agencyWebConfigData.configs.banner"
                    :src="agencyWebConfigData.configs.banner"
                    center
                    class="p-50"
                    style="max-height: 100%; max-width: 100%"
                    :alt="$t('imgNotFound')"
                  />
                </validation-provider>
              </b-col>

              <!-- ANCHOR: favicon -->
              <b-col md="6">
                <validation-provider
                  #default="{ valid, errors }"
                  :name="$t(`agency.webConfig.field.favicon`)"
                  vid="favicon"
                  rules="url"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="favicon">{{ $t(`agency.webConfig.field.favicon`) }}</label>
                    </div>
                    <b-input-group class="input-group custom-file-button">
                      <b-form-input
                        id="favicon"
                        v-model="agencyWebConfigData.configs.favicon"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isDisabledInput"
                        :name="$t(`agency.webConfig.field.favicon`)"
                        :placeholder="$t(`agency.webConfig.ph.logo`)"
                      />
                      <template
                        v-if="!isDisabledInput"
                        #append
                      >
                        <label
                          for="favicon-file"
                          class="btn btn-warning px-25 mb-0"
                        >
                          {{ $t('agency.uploadImage') }}
                        </label>
                      </template>
                    </b-input-group>

                    <small class="text-danger">
                      (*) Ảnh favicon có tỉ lệ 1:1
                    </small>
                    <BFormInvalidFeedback
                      v-if="errors[0]"
                      class="text-danger"
                      style="display: block !important"
                    >
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                  <b-form-file
                    id="favicon-file"
                    ref="faviconRef"
                    v-model="faviconFile"
                    accept=".jpg, .png"
                    :disabled="isDisabledInput"
                    plain
                    class="d-none"
                    @input="inputImageFaviconFormData"
                  />
                  <div class="d-flex align-items-center">
                    <b-img-lazy
                      v-if="valid && agencyWebConfigData.configs.favicon"
                      :src="agencyWebConfigData.configs.favicon"
                      center
                      class="p-50 border mt-2"
                      style="max-height: 30%; max-width: 40%;"
                      :alt="$t('imgNotFound')"
                    />
                  </div>
                </validation-provider>
              </b-col>

              <!-- ANCHOR: logo noti -->
              <b-col md="6">
                <validation-provider
                  #default="{ valid, errors }"
                  :name="$t(`agency.webConfig.field.logoNoti`)"
                  vid="logoNoti"
                  rules="url"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="logoNoti">{{ $t(`agency.webConfig.field.logoNoti`) }}</label>
                    </div>
                    <b-input-group class="input-group custom-file-button">
                      <b-form-input
                        id="logoNoti"
                        v-model="agencyWebConfigData.configs.logoNoti"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isDisabledInput"
                        :name="$t(`agency.webConfig.field.logoNoti`)"
                        :placeholder="$t(`agency.webConfig.ph.logo`)"
                      />
                      <template
                        v-if="!isDisabledInput"
                        #append
                      >
                        <label
                          for="logoNoti-file"
                          class="btn btn-warning px-25 mb-0"
                        >
                          {{ $t('agency.uploadImage') }}
                        </label>
                      </template>
                    </b-input-group>

                    <small class="text-danger">
                      (*) Ảnh logo thông báo có tỉ lệ 1:1
                    </small>
                    <BFormInvalidFeedback
                      v-if="errors[0]"
                      class="text-danger"
                      style="display: block !important"
                    >
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                  <b-form-file
                    id="logoNoti-file"
                    ref="logoNotiRef"
                    v-model="logoNotiFile"
                    accept=".jpg, .png"
                    :disabled="isDisabledInput"
                    plain
                    class="d-none"
                    @input="inputImageLogoNotiFormData"
                  />
                  <div class="d-flex align-items-center">
                    <b-img-lazy
                      v-if="valid && agencyWebConfigData.configs.logoNoti"
                      :src="agencyWebConfigData.configs.logoNoti"
                      center
                      class="p-50 border mt-2"
                      style="max-height: 30%; max-width: 40%;"
                      :alt="$t('imgNotFound')"
                    />
                  </div>
                </validation-provider>
              </b-col>

              <!-- ANCHOR: banner search -->
              <b-col md="6">
                <validation-provider
                  #default="{ invalid, errors }"
                  :name="$t(`agency.webConfig.field.bannerSearch`)"
                  vid="bannerSearch"
                  rules="url"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="bannerSearch">
                        {{ $t(`agency.webConfig.field.bannerSearch`) }}
                      </label>
                    </div>
                    <b-input-group class="input-group custom-file-button">
                      <b-form-input
                        id="bannerSearch"
                        v-model="bannerSearchUrlToAdd"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isDisabledInput"
                        :name="$t(`agency.webConfig.field.bannerSearch`)"
                        :placeholder="$t(`agency.webConfig.ph.banner`)"
                        @keyup.enter="addBannerSearch"
                      />

                      <template
                        v-if="!isDisabledInput"
                        #append
                      >
                        <BButton
                          class="py-50 px-75"
                          variant="info"
                          :disabled="invalid"
                          @click="addBannerSearch"
                        >
                          <IAmIcon
                            icon="plusCircleOutline"
                            size="18"
                          />
                        </BButton>
                        <label
                          for="banner-search-file"
                          class="btn btn-warning px-25 mb-0"
                        >
                          {{ $t('agency.uploadImage') }}
                        </label>
                      </template>
                    </b-input-group>

                    <small class="text-danger">
                      (*) Ảnh banner màn tìm kiếm chuyến bay có tỉ lệ 4:1, ví dụ: 1200x300 (px). Kích thước ảnh nhỏ hơn 1MB.
                    </small>
                    <BFormInvalidFeedback
                      v-if="errors[0]"
                      class="text-danger"
                      style="display: block !important"
                    >
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                  <b-form-file
                    id="banner-search-file"
                    ref="bannerSearchRef"
                    v-model="bannerSearchFile"
                    accept=".jpg, .png"
                    :disabled="isDisabledInput"
                    plain
                    multiple
                    class="d-none"
                    @input="onUploadBannerSearchHandle"
                  />
                  <div v-if="isArray(agencyWebConfigData.configs?.bannerSearch) && agencyWebConfigData.configs?.bannerSearch?.length">
                    <div
                      v-for="(bannerSearchItem, bannerSearchIndex) in agencyWebConfigData.configs?.bannerSearch"
                      :key="bannerSearchIndex"
                      class="d-flex-between gap-1 flex-nowrap"
                    >
                      <div>
                        <em>{{ bannerSearchIndex + 1 }}. </em>
                        <a
                          :href="bannerSearchItem"
                          :alt="`Banner ${bannerSearchIndex + 1}`"
                          target="_blank"
                          class="hover-underline"
                        >
                          <span class="text-truncate">{{ shortenUrl(bannerSearchItem) }}</span>
                        </a>
                      </div>
                      <b-button
                        v-if="!isDisabledInput"
                        variant="flat-danger"
                        class="p-50 rounded-circle"
                        @click="removeBannerSearch(bannerSearchIndex)"
                      >
                        <IAmIcon icon="closeOutline" />
                      </b-button>
                    </div>
                  </div>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- SECTION SMTP Options -->
        <b-card
          v-if="isRoleF1"
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mb-0 mt-1"
          no-body
        >
          <template #header>
            <div class="d-flex align-items-center justify-content-between w-100">
              <h5 class="m-0">
                {{ $t('agency.webConfig.smtpOptions') }}
              </h5>

              <b-button
                v-if="useSMTP && haveWebConfig && isDisabledInput"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                @click="()=> {
                  emailTest = cloneDeep(blankEmailTest)
                  $bvModal.show('test-send-email-modal')
                } "
              >
                Test Send Email
              </b-button>
              <BFormCheckbox
                v-model="useSMTP"
                :disabled="isDisabledInput"
                switch
                @change="(value)=>{
                  if (!value) {
                    agencyWebConfigData.smtpOptions = {
                      host: '',
                      port: '',
                      username: '',
                      password: '',
                      applyForEmployee:false
                    }
                  } else {
                    agencyWebConfigData.smtpOptions = {
                      host: 'smtp.gmail.com',
                      port: '587',
                      username: '',
                      password: '',
                      applyForEmployee:true
                    }
                  }
                }"
              />
            </div>
          </template>
          <b-card-body
            v-if="useSMTP"
            class="p-1"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.emailType`)"
                  vid="emailType"
                  rules="required"
                >
                  <b-form-group :class="{ 'is-invalid': errors[0] }">
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="emailType">{{ $t(`agency.webConfig.field.emailType`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>

                    <v-select
                      id="emailType"
                      v-model="emailType"
                      :name="$t('agency.webConfig.field.emailType')"
                      :options="emailTypeOptions"
                      :clearable="false"
                      :disabled="isDisabledInput"
                      label="label"
                      :placeholder="$t('agency.webConfig.ph.emailType')"
                      :reduce="val => val.value"
                      @input="val => onEmailTypeChange(val)"
                    >
                      <template #option="{ label, icon }">
                        <div class="d-flex gap-1">
                          <IAmIcon
                            :icon="icon"
                            size="16"
                          />
                          {{ $t(`agency.webConfig.emailType.${label}`) }}
                        </div>
                      </template>
                      <template #selected-option="{ label, icon }">
                        <div class="d-flex gap-1">
                          <IAmIcon
                            :icon="icon"
                            size="16"
                          />
                          {{ $t(`agency.webConfig.emailType.${label}`) }}
                        </div>
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.host`)"
                  vid="host"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="host">{{ $t(`agency.webConfig.field.host`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>
                    <b-form-input
                      id="host"
                      v-model="agencyWebConfigData.smtpOptions.host"
                      :disabled="isDisabledInput"
                      :state="errors.length > 0 ? false : null"
                      :name="$t(`agency.webConfig.field.host`)"
                      :placeholder="$t(`agency.webConfig.ph.host`)"
                    />
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.email`)"
                  vid="username"
                  rules="required|email"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="username">{{ $t(`agency.webConfig.field.email`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>
                    <b-form-input
                      id="username"
                      v-model="agencyWebConfigData.smtpOptions.username"
                      autocomplete="off"
                      type="email"
                      :disabled="isDisabledInput"
                      :state="errors.length > 0 ? false : null"
                      :name="$t(`agency.webConfig.field.email`)"
                      :placeholder="$t(`agency.webConfig.ph.email`)"
                    />
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.password`)"
                  vid="password"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="password">{{ $t(`agency.webConfig.field.password`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>
                    <b-form-input
                      id="password"
                      v-model="agencyWebConfigData.smtpOptions.password"
                      autocomplete="new-password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isDisabledInput"
                      :name="$t(`agency.webConfig.field.password`)"
                      :placeholder="$t(`agency.webConfig.ph.password`)"
                      @input="$event => {
                        agencyWebConfigData.smtpOptions.password = $event.replace(/\s+/g, '');;
                      }
                      "
                    />
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`agency.webConfig.field.port`)"
                  vid="port"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex gap-1 font-weight-bolder">
                      <label for="port">{{ $t(`agency.webConfig.field.port`) }}</label>
                      <span class="text-danger">(*)</span>
                    </div>
                    <b-form-input
                      id="port"
                      v-model="agencyWebConfigData.smtpOptions.port"
                      :disabled="isDisabledInput"
                      :state="errors.length > 0 ? false : null"
                      :name="$t(`agency.webConfig.field.port`)"
                      :placeholder="$t(`agency.webConfig.ph.port`)"
                    />
                    <BFormInvalidFeedback class="text-danger">
                      {{ errors[0] }}
                    </BFormInvalidFeedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <div class="d-flex gap-1 font-weight-bolder align-items-center">
                    <label for="applyForEmployee">{{ $t(`agency.webConfig.field.applyForEmployee`) }}</label>
                    <span class="text-danger">(*)</span>
                  </div>
                  <BFormCheckbox
                    v-model="agencyWebConfigData.smtpOptions.applyForEmployee"
                    :disabled="isDisabledInput"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- SECTION buttons -->
        <b-row class="mt-50">
          <b-col
            cols="12"
            class="d-flex-center gap-1"
          >
            <b-button
              v-if="!haveWebConfig"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createHandle"
            >
              {{ $t('create') }}
            </b-button>
            <b-button
              v-if="haveWebConfig && isEdit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              @click="updateHandle"
            >
              {{ $t('saveChanges') }}
            </b-button>
            <b-button
              v-if="isDisabledInput"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              @click="isEdit = true"
            >
              {{ $t('edit') }}
            </b-button>
            <b-button
              v-if="haveWebConfig && isEdit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              @click="confirmCancelEdit(pristine)"
            >
              {{ $t('cancelEdit') }}
            </b-button>
          </b-col>
        </b-row>
        <!-- !SECTION -->
      </validation-observer>
    </IAmOverlay>

    <b-modal
      id="test-send-email-modal"
      centered
      no-close-on-esc
      no-close-on-backdrop
      :title="`Test send email`"
    >
      <validation-observer ref="refFormObserver">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            :name="$t(`agency.webConfig.field.email`)"
            vid="emailTest"
            rules="required|email"
          >
            <b-form-group>
              <div class="d-flex gap-1 font-weight-bolder">
                <label for="emailTest">{{ $t(`agency.webConfig.field.email`) }}</label>
              </div>
              <b-input-group class="input-group custom-file-button">
                <b-form-input
                  id="emailTest"
                  v-model="emailTest.email"
                  :state="errors.length > 0 ? false : null"
                  :name="$t(`agency.webConfig.field.email`)"
                  :placeholder="$t(`agency.webConfig.ph.email`)"
                />
              </b-input-group>
              <BFormInvalidFeedback
                v-if="errors[0]"
                class="text-danger"
                style="display: block !important"
              >
                {{ errors[0] }}
              </BFormInvalidFeedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            :name="$t(`agency.webConfig.field.content`)"
            vid="content"
            rules="required"
          >
            <b-form-group>
              <div class="d-flex gap-1 font-weight-bolder">
                <label for="content">{{ $t(`agency.webConfig.field.content`) }}</label>
              </div>
              <b-input-group class="input-group custom-file-button">
                <b-form-input
                  id="content"
                  v-model="emailTest.content"
                  :state="errors.length > 0 ? false : null"
                  :name="$t(`agency.webConfig.field.content`)"
                  :placeholder="$t(`agency.webConfig.ph.content`)"
                />
              </b-input-group>
              <BFormInvalidFeedback
                v-if="errors[0]"
                class="text-danger"
                style="display: block !important"
              >
                {{ errors[0] }}
              </BFormInvalidFeedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="testSendEmail"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  computed,
  onMounted,
  ref,
  toRefs,
} from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BModal,
  BCol,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImgLazy,
  BInputGroup,
  BRow,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import isArray from 'lodash/isArray'

import { convertISODateTime } from '@/@core/utils/filter'
import store from '@/store'

import { uploadMultipleFile, useInputImageFormData } from '@core/comp-functions/forms/form-utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  getDifference, shortenUrl, stringOrArrayToArray, stringOrArrayToString,
} from '@core/utils/utils'

import useAgenciesHandle from '@agencies/useAgenciesHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BAlert,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormFile,
    BImgLazy,
    BInputGroup,
    BModal,
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    agencyId: {
      type: [Number, null],
      default: null,
    },
    agencyWebConfig: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()
    const meData = computed(() => store.getters['userStore/getMeData'])
    const {
      createWebConfig, updateWebConfig, sendEmailTest, loading,
    } = useAgenciesHandle()
    const { refFormObserver } = formValidation()
    const { agencyId, agencyWebConfig: agencyWebConfigProp } = toRefs(props)

    const isEdit = ref(false)
    const useSMTP = ref(false)

    function resolveAgencyConfig(agencyConfig) {
      if (!agencyConfig) return null
      return {
        ...agencyConfig,
        configs: agencyConfig.configs.reduce((a, c) => ({
          ...a,
          [c.key]: ['bannerSearch'].includes(c.key)
            ? stringOrArrayToArray(c.value)
            : stringOrArrayToString(c.value),
        }), { bannerSearch: [] }),
      }
    }

    const blankAgencyWebConfig = {
      websiteUrl: '',
      useSeparateATS: false,
      title: '',
      configs: {
        logo: '',
        banner: '',
        bannerSearch: [],
        email: '',
        favicon: '',
      },
      smtpOptions: {
        host: 'smtp.gmail.com',
        port: '587',
        username: '',
        password: '',
      },
    }
    const blankEmailTest = {
      email: '',
      content: '',
    }
    const haveWebConfig = computed(() => !!(props?.agencyWebConfig))
    const agencyWebConfigData = ref()
    const emailTest = ref(cloneDeep(blankEmailTest))

    function resetData() {
      agencyWebConfigData.value = cloneDeep(haveWebConfig.value ? resolveAgencyConfig(agencyWebConfigProp.value) : blankAgencyWebConfig)
    }
    resetData()
    async function createHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      const payload = {
        ...agencyWebConfigData.value,
        configs: Object.keys(agencyWebConfigData.value.configs).map(key => ({
          category: key,
          language: 'vi',
          key,
          value: agencyWebConfigData.value.configs[key],
        })),
      }
      await createWebConfig(agencyId.value, payload)
      emit('refetchAgency')
    }

    async function updateHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      const dataToUpdate = getDifference(agencyWebConfigData.value, resolveAgencyConfig(agencyWebConfigProp.value))
      if (!isEmpty(dataToUpdate)) {
        const payload = {
          ...dataToUpdate,
          websiteUrl: agencyWebConfigData.value.websiteUrl,
          configs: dataToUpdate?.configs
            ? Object.keys(dataToUpdate?.configs)
              .map(key => ({
                category: key,
                language: 'vi',
                key,
                value: agencyWebConfigData.value.configs[key],
              }))
            : undefined,
        }
        delete payload.updatedAt
        delete payload.createdAt
        await updateWebConfig(agencyId.value, payload)
        emit('refetchAgency')
      }
      isEdit.value = false
    }

    function confirmCancelEdit(pristine = true) {
      if (!pristine) {
        this.$bvModal
          .msgBoxConfirm(this.$t('modal.confirmCancelEdit'), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('modal.iamConfirm'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              isEdit.value = false
              resetData()
            }
          })
      } else {
        isEdit.value = false
        resetData()
      }
    }

    const isDisabledInput = computed(() => haveWebConfig.value && !isEdit.value)

    // SMTP Configs
    const emailTypeOptions = ref([
      {
        label: 'Google mail', value: 'GOOGLE', icon: 'google', hostDefault: 'smtp.gmail.com', portDefault: '587',
      },
      {
        label: 'Yahoo mail', value: 'YAHOO', icon: 'yahoo', hostDefault: 'smtp.mail.yahoo.com', portDefault: '465',
      },
      { label: 'Other', value: 'OTHER', icon: 'internet' },
    ])

    const emailType = ref('GOOGLE')

    function onEmailTypeChange(val) {
      const emailType = emailTypeOptions.value.find(item => item.value === val)
      agencyWebConfigData.value.smtpOptions.host = emailType?.hostDefault ?? ''
      agencyWebConfigData.value.smtpOptions.port = emailType?.portDefault ?? ''
    }

    // Upload handle
    const logoRef = ref()
    const bannerRef = ref()
    const bannerSearchRef = ref()
    const faviconRef = ref()
    const logoNotiRef = ref()
    const logoFile = ref()
    const bannerFile = ref()
    const bannerSearchFile = ref()
    const faviconFile = ref()
    const logoNotiFile = ref()

    const bannerSearchUrlToAdd = ref()

    const { inputImageFormData: inputImageLogoFormData } = useInputImageFormData(logoRef, data => {
      agencyWebConfigData.value.configs.logo = data.url
    })

    const { inputImageFormData: inputImageBannerFormData } = useInputImageFormData(bannerRef, data => {
      agencyWebConfigData.value.configs.banner = data.url
    })

    const { inputImageFormData: inputImageFaviconFormData } = useInputImageFormData(faviconRef, data => {
      agencyWebConfigData.value.configs.favicon = data.url
    })

    const { inputImageFormData: inputImageLogoNotiFormData } = useInputImageFormData(logoNotiRef, data => {
      agencyWebConfigData.value.configs.logoNoti = data.url
    })

    const loadingUpload = ref(false)
    async function onUploadBannerSearchHandle() {
      loadingUpload.value = true
      try {
        const files = bannerSearchRef.value.files
        const uploadedFile = await uploadMultipleFile(files, { uploadUser: false })
        agencyWebConfigData.value.configs.bannerSearch = [...agencyWebConfigData.value.configs.bannerSearch, ...uploadedFile.map(i => i.url)]
      } catch (error) {
        toastError(error.message)
      } finally {
        loadingUpload.value = false
      }
    }

    function removeBannerSearch(index) {
      agencyWebConfigData.value.configs.bannerSearch.splice(index, 1)
    }

    function addBannerSearch() {
      agencyWebConfigData.value.configs.bannerSearch.push(bannerSearchUrlToAdd.value)
      bannerSearchUrlToAdd.value = ''
    }

    onMounted(() => {
      if (haveWebConfig.value && agencyWebConfigProp.value.smtpOptions) {
        const { username, password } = agencyWebConfigProp.value.smtpOptions
        useSMTP.value = !!username && !!password
      } else {
        useSMTP.value = true
      }
    })

    async function testSendEmail() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      await sendEmailTest(emailTest.value)
      emailTest.value = cloneDeep(blankEmailTest)
      this.$bvModal.hide('test-send-email-modal')
    }
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    return {
      agencyWebConfigData,
      refFormObserver,
      createHandle,
      convertISODateTime,
      isEdit,
      updateHandle,
      confirmCancelEdit,
      loading,
      haveWebConfig,
      isDisabledInput,

      emailTypeOptions,
      emailType,
      onEmailTypeChange,
      logoRef,
      logoFile,
      inputImageLogoFormData,
      bannerRef,
      bannerFile,
      bannerSearchRef,
      bannerSearchFile,
      faviconRef,
      faviconFile,
      inputImageBannerFormData,
      meData,
      useSMTP,
      testSendEmail,
      emailTest,
      blankEmailTest,
      cloneDeep,
      isRoleF1,
      inputImageFaviconFormData,
      inputImageLogoNotiFormData,
      logoNotiRef,
      logoNotiFile,
      onUploadBannerSearchHandle,
      bannerSearchUrlToAdd,
      loadingUpload,
      removeBannerSearch,
      addBannerSearch,
      shortenUrl,
      isArray,
    }
  },
}
</script>
<style lang="">

</style>

import { apiSaleReports } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAccountPayable(ctx, params) {
      return apiSaleReports.fetchAccountPayable(params)
    },

    accountPayableDetail(ctx, params) {
      return apiSaleReports.accountPayableDetail(params)
    },

    updateSaleReport(ctx, saleReports) {
      return apiSaleReports.updateSaleReport(saleReports)
    },

    getDate() {
      return apiSaleReports.getDate()
    },

    bookClosing(ctx, params) {
      return apiSaleReports.bookClosing(params)
    },

    exportAccountPayable(ctx, payload, fileName = 'Account-Payable') {
      return apiSaleReports.exportAccountPayable(payload, fileName)
    },

    exportAccountPayableDetail(ctx, payload, fileName = 'Sale-Report') {
      return apiSaleReports.exportAccountPayableDetail(payload, fileName)
    },

    exportSaleReportTemplate(ctx, payload, fileName = 'Sale-Report-Template') {
      return apiSaleReports.exportSaleReportTemplate(payload, fileName)
    },

    // Retail
    fetchRetailAccountPayableList(ctx, params) {
      return apiSaleReports.fetchRetailAccountPayableList(params)
    },

    getRetailAccountPayableDetail(ctx, params) {
      return apiSaleReports.getRetailAccountPayableDetail(params)
    },
  },
}

import { computed, ref, watch } from '@vue/composition-api'

import { apiAgencies } from '@/api'
import store from '@/store'

import { convertISODateTime } from '@core/utils/filter'

import useToast from '@useToast'

export default function useAccountsPayableF2Handle() {
  // eslint-disable-next-line no-unused-vars
  const { toastSuccess, toastError } = useToast()

  const refAccountPayableF2ListTable = ref(null)
  const refAccountPayableF2DetailTable = ref(null)

  const tableListColumns = [
    { label: 'no', key: 'no' },
    // { label: 'checkbox', key: 'checkbox' },
    { label: 'agCode', key: 'agCode' },
    { label: 'agName', key: 'agName' },
    { label: 'openingBalance', key: 'openingBalance' },
    { label: 'receivables', key: 'receivables' },
    { label: 'paid', key: 'paid' },
    { label: 'closingBalance', key: 'closingBalance' },
    // { label: 'tradeCreditors', key: 'tradeCreditors' },
    // { label: 'action', key: 'action' },
  ]

  const tableListColumnsF3 = [
    { label: 'no', key: 'no' },
    // { label: 'checkbox', key: 'checkbox' },
    { label: 'agCode', key: 'agCode' },
    { label: 'agName', key: 'name' },
    { label: 'receivables', key: 'receivables' },
    { label: 'tradeCreditors', key: 'tradeCreditors' },
    // { label: 'action', key: 'action' },
  ]

  const tableDetailColumns = [
    { label: 'no', key: 'no' },
    { label: 'airline', key: 'airline' },
    { label: 'source', key: 'source' },
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'paxName', key: 'paxName' },
    { label: 'paxType', key: 'paxType' },
    // { label: 'paxId', key: 'paxId' },
    { label: 'ticketNumber', key: 'ticketNumber' },
    { label: 'ticketType', key: 'ticketType' },
    { label: 'flightType', key: 'flightType' },
    { label: 'trips', key: 'trips' },
    { label: 'flightDates', key: 'flightDates' },
    { label: 'bookingClass', key: 'bookingClass' },
    { label: 'fareBasisCode', key: 'fareBasisCode' },
    { label: 'bookingDate', key: 'bookingDate' },
    { label: 'issueDate', key: 'issueDate' },
    { label: 'booker', key: 'booker' },
    { label: 'issuer', key: 'issuer' },
    { label: 'agency', key: 'agency' },
    { label: 'signBook', key: 'signBook' },
    { label: 'currency', key: 'currency' },
    { label: 'priceTicket', key: 'priceTicket' },
    { label: 'vat', key: 'tax' },
    { label: 'otherTax', key: 'otherTax' },
    { label: 'tax', key: 'collectionFee' },
    // { label: 'fee', key: 'fee' },
    { label: 'feeIn', key: 'feeIn' },
    { label: 'feeOut', key: 'feeOut' },
    { label: 'feeService', key: 'feeService' },
    // { label: 'commissionPrepaid', key: 'commissionPrepaid' },
    { label: 'commissionPrepaidIn', key: 'commissionPrepaidIn' },
    { label: 'commissionPrepaidOut', key: 'commissionPrepaidOut' },
    { label: 'unpaidCommission', key: 'unpaidCommission' },
    { label: 'commission', key: 'commission' },
    // { label: 'totalPayment', key: 'totalPayment' },
    { label: 'tradeCreditors', key: 'tradeCreditors' },
    { label: 'receivables', key: 'receivables' },
    { label: 'profit', key: 'profit' },
    // { label: 'accountsPayable', key: 'accountsPayable' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    // { label: 'actions', key: 'actions' },
  ]

  const sizePerPage = ref(10)
  const totalAccountPayable = ref(0)
  const currentPage = ref(1)

  const itemsAccountPayableF2ListTable = ref()
  const itemsAccountPayableF3ListTable = ref()

  // Loading
  const loading = ref(null)

  // ANCHOR Filter list
  const fromDateFilter = ref(convertISODateTime(new Date()).dateFilter)
  const toDateFilter = ref(convertISODateTime(new Date()).dateFilter)
  const agencyListFilter = ref(null)

  // let filtersParamsDetail = {}

  const totalOpeningBalance = ref(null)
  const totalClosingBalance = ref(null)
  const totalTradeCreditor = ref(null)
  const totalReceivables = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAccountPayableF2ListTable.value
      ? refAccountPayableF2ListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAccountPayable.value,
    }
  })

  const refetchData = () => {
    refAccountPayableF2ListTable.value.refresh()
  }

  // eslint-disable-next-line no-unused-vars
  function refetchDataDetail($event = {}) {
    // filtersParamsDetail = { ...$event }
    refAccountPayableF2DetailTable.value.refresh()
  }

  const clearFilter = () => {
    fromDateFilter.value = convertISODateTime(new Date()).dateFilter
    toDateFilter.value = convertISODateTime(new Date()).dateFilter
    agencyListFilter.value = null

    refetchData()
  }

  watch([currentPage, sizePerPage], () => {
    refetchData()
  })

  const fetchAccountPayableF2 = () => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-account-payable-f2/fetchListAccountsPayableF2', {
        agencyId: agencyListFilter.value.id,
        params: {
          startDate: fromDateFilter.value,
          endDate: toDateFilter.value,
        },
      })
      .then(response => {
        totalAccountPayable.value = response.data.total
        const { data } = response
        totalTradeCreditor.value = data.totalTradeCreditor
        totalReceivables.value = data.totalReceivables
        totalOpeningBalance.value = data.totalOpeningBalance
        totalClosingBalance.value = data.totalClosingBalance
        resolve(data.items)
        itemsAccountPayableF2ListTable.value = data.items
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.fetchListError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const fetchAccountPayableF3 = () => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-account-payable-f2/fetchListAccountsPayableF3', {
        agencyId: null,
        params: {
          startDate: fromDateFilter.value,
          endDate: toDateFilter.value,
        },
      })
      .then(response => {
        totalAccountPayable.value = response.data.total
        const { data } = response
        totalTradeCreditor.value = data.totalTradeCreditor
        totalReceivables.value = data.totalReceivables
        totalOpeningBalance.value = data.totalOpeningBalance
        totalClosingBalance.value = data.totalClosingBalance
        resolve(data.items)
        itemsAccountPayableF3ListTable.value = data.items
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.fetchListError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // const fetchAccountPayableDetail = () => {
  //   loading.value = true

  //   if (
  //     typeof filtersParamsDetail === 'object'
  //     && Object.keys(filtersParamsDetail).length === 0
  //   ) {
  //     filtersParamsDetail = {
  //       startDate: convertISODateTime(new Date()).dateFilter,
  //       endDate: convertISODateTime(new Date()).dateFilter,
  //     }
  //   }

  //   store
  //     .dispatch('app-account-payable/accountPayableDetail', {
  //       ...filtersParamsDetail,
  //     })
  //     .then(response => {
  //       totalAccountPayable.value = response.data.total
  //       const { data } = response
  //       console.log({ data })
  //       totalTradeCreditor.value = data.totalTradeCreditor
  //       totalReceivables.value = data.totalReceivables
  //       totalOpeningBalance.value = data.totalOpeningBalance
  //       totalClosingBalance.value = data.totalClosingBalance
  //       // callback(data.items)
  //       loading.value = false
  //     })
  //     .catch(() => {
  //       toastError({
  //         title: 'messagesList.error',
  //         content: 'messagesList.accountPayable.fetchDetailError',
  //       })
  //       loading.value = false
  //     })
  // }

  // const getDate = () => new Promise((resolve, reject) => {
  //   store
  //     .dispatch('app-account-payable/getDate')
  //     .then(res => {
  //       resolve(res)
  //     })
  //     .catch(e => {
  //       toastError({
  //         title: 'messagesList.error',
  //         content: e,
  //       })
  //       reject(e)
  //     })
  // })

  const exportAccountPayableListF2 = () => {
    store
      .dispatch('app-account-payable-f2/exportListAccountsPayableF2', {
        agencyId: agencyListFilter.value.id,
        params: {
          startDate: fromDateFilter.value,
          endDate: toDateFilter.value,
        },
      })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.exportError',
        })
      })
  }

  const exportAccountPayableDetailF2 = filtersParamsDetail => {
    store
      .dispatch(
        'app-account-payable-f2/exportDetailAccountsPayableF2',
        filtersParamsDetail,
      )
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.accountPayable.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.accountPayable.exportError',
        })
      })
  }

  const fetchAgencies = (searchText = undefined) => new Promise((resolve, reject) => {
    loading.value = true
    apiAgencies
      .fetchAgencies({ searchText })
      .then(response => { resolve(response.data.items) })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  return {
    fetchAccountPayableF2,
    exportAccountPayableListF2,
    // fetchAccountPayableDetail,
    exportAccountPayableDetailF2,
    itemsAccountPayableF2ListTable,
    itemsAccountPayableF3ListTable,
    fetchAccountPayableF3,
    tableListColumns,
    tableListColumnsF3,
    tableDetailColumns,
    sizePerPage,
    currentPage,
    totalAccountPayable,
    dataMeta,

    // Refs
    refAccountPayableF2ListTable,
    refAccountPayableF2DetailTable,

    refetchData,
    refetchDataDetail,
    clearFilter,

    // Extra Filters
    agencyListFilter,
    fromDateFilter,
    toDateFilter,
    totalOpeningBalance,
    totalClosingBalance,
    totalTradeCreditor,
    totalReceivables,

    // Loading
    loading,

    fetchAgencies,
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "border mb-0 mt-2",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.managerInfo')) + " ")]), _c('div', [_c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "info",
            "disabled": !_vm.canAccess('agency.assignManager') || !_vm.isDirty
          },
          on: {
            "click": _vm.saveHandle
          }
        }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('employee.save')))])])], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "p-2 pt-2"
  }, [_c('AgenciesEditSearchAgencies', {
    on: {
      "select-employee": _vm.addToList
    }
  }), _c('b-table', {
    ref: "refEmployeesListTable",
    staticClass: "rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "items": _vm.managerEmployeesItems,
      "fields": _vm.tableColumns,
      "primary-key": "id"
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("agency.managerEmployeesTable.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(employeeCode)",
      fn: function fn(data) {
        return [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
          attrs: {
            "to": {
              name: 'apps-employees-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.employeeCode) + " ")])];
      }
    }, {
      key: "cell(employeeName)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('span', [_vm._v(" " + _vm._s(data.item.firstName) + " " + _vm._s(data.item.lastName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(data.item.type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(data.item.type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap text-right"
        }, [_c('b-button', {
          staticClass: "py-50 px-1",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.removeItemToList(data.item.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "id": "invoice-row-".concat(data.item.id, "-trash-icon"),
            "icon": "TrashIcon",
            "size": "16"
          }
        })], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "be-manager"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('agency.BEManager')) + " ")];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "be-manager",
      "options": _vm.agenciesManagerList.filter(function (i) {
        return i.type === 'BE';
      }),
      "reduce": function reduce(val) {
        return val;
      },
      "label": "id",
      "input-id": "be-manager",
      "placeholder": _vm.$t('placeholderSelect'),
      "disabled": !_vm.canAccess('agency.assignManager'),
      "searchable": "",
      "clearable": false,
      "filterable": false
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var firstName = _ref.firstName,
          lastName = _ref.lastName,
          type = _ref.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var firstName = _ref2.firstName,
          lastName = _ref2.lastName,
          type = _ref2.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.BEManager,
      callback: function callback($$v) {
        _vm.BEManager = $$v;
      },
      expression: "BEManager"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "se-manager"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('agency.SEManager')) + " ")];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "se-manager",
      "options": _vm.agenciesManagerList.filter(function (i) {
        return i.type === 'SE';
      }),
      "reduce": function reduce(val) {
        return val;
      },
      "label": "id",
      "input-id": "se-manager",
      "disabled": !_vm.canAccess('agency.assignManager'),
      "placeholder": _vm.$t('placeholderSelect'),
      "searchable": "",
      "clearable": false,
      "filterable": false
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref3) {
        var firstName = _ref3.firstName,
          lastName = _ref3.lastName,
          type = _ref3.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "option",
      fn: function fn(_ref4) {
        var firstName = _ref4.firstName,
          lastName = _ref4.lastName,
          type = _ref4.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.SEManager,
      callback: function callback($$v) {
        _vm.SEManager = $$v;
      },
      expression: "SEManager"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "ktv-manager"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('agency.KTVManager')) + " ")];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "ktv-manager",
      "options": _vm.agenciesManagerList.filter(function (i) {
        return ['KTV', 'KTT'].includes(i.type);
      }),
      "reduce": function reduce(val) {
        return val;
      },
      "label": "id",
      "input-id": "ktv-manager",
      "placeholder": _vm.$t('placeholderSelect'),
      "searchable": "",
      "clearable": false,
      "filterable": false,
      "disabled": !_vm.canAccess('agency.assignManager')
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref5) {
        var firstName = _ref5.firstName,
          lastName = _ref5.lastName,
          type = _ref5.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "option",
      fn: function fn(_ref6) {
        var firstName = _ref6.firstName,
          lastName = _ref6.lastName,
          type = _ref6.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mx-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.KTVManager,
      callback: function callback($$v) {
        _vm.KTVManager = $$v;
      },
      expression: "KTVManager"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <validation-observer
    ref="refFormObserver"
    #default="{invalid}"
  >
    <b-modal
      id="modal-agency-add-social-network"
      title="Mạng xã hội"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      lazy
      size="lg"
      no-close-on-backdrop
      @show="handleModalOpen"
      @hide="handleModalClose"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-lg-25"
          variant="info"
          :disabled="invalid"
          pill
          @click="submitAddSocialNetwork"
        >
          <span class="align-middle">Xác nhận</span>
        </b-button>
      </template>

      <div>
        <b-form
          ref="form"
          @submit="submitAddSocialNetwork"
          @keyup.enter="submitAddSocialNetwork"
        >
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in dataToAdd"
            :key="index"
            ref="row"
            class="mx-0"
            no-gutters
          >
            <!-- Tên mạng xã hội -->
            <b-col
              cols="12"
              md="4"
              class="pr-md-1"
            >
              <validation-provider
                #default="validationContext"
                name="Tên mạng xã hội"
                rules="required"
              >
                <b-form-group
                  :label="`Mạng xã hội #${index + 1}`"
                >
                  <b-form-input
                    :id="`number-${index}`"
                    v-model="item.type"
                    class="font-weight-bold text-dark font-medium-2"
                    :class="item.isDel ? 'border-danger text-decoration-line' : ''"
                    :disabled="item.isDel"
                    :state="getValidationState(validationContext) === false ? false : null"
                    placeholder="Nhập vào"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Địa chỉ -->
            <b-col
              cols="12"
              md="7"
            >
              <validation-provider
                #default="validationContext"
                name="Địa chỉ"
                rules="required"
              >
                <b-form-group
                  label="Địa chỉ (Url)"
                >
                  <b-form-input
                    :id="`url-${index}`"
                    v-model="item.url"
                    :disabled="item.isDel"
                    class="font-weight-bold text-dark font-medium-2"
                    :class="item.isDel ? 'border-danger text-decoration-line' : ''"
                    :state="getValidationState(validationContext) === false ? false : null"
                    placeholder="Nhập vào"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - Remove Button -->
            <b-col
              cols="12"
              md="1"
              class="mb-50 d-flex align-items-end justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                :variant="item.isDel ? 'outline-info' : 'outline-danger'"
                :disabled="(dataToAdd.length === 1) && item.isNew"
                class="p-50 d-flex rounded-lg"
                style="margin-bottom: 7px;"
                @click="toggleItemDelete(index)"
              >
                <feather-icon
                  v-if="item.isDel"
                  icon="RotateCcwIcon"
                  size="20"
                />
                <feather-icon
                  v-else
                  icon="Trash2Icon"
                  size="20"
                />
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr class="mt-0">
            </b-col>
          </b-row>
        </b-form>
      </div>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        class="ml-75 py-25 rounded-pill d-flex-center"
        @click="handleAddNewItem"
      >
        <feather-icon
          icon="PlusIcon"
          size="25"
        />
      </b-button>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BFormGroup, BButton, BForm, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import {
  ref, toRefs,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'

import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'

// import useAgenciesHandle from '../useAgenciesHandle'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BForm,
    BFormInput,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    agencyToEdit: {
      type: Object,
      required: true,
    },
    agenciesData: {
      type: Object,
      required: true,
    },
    socialAccounts: {
      type: [Array, null],
      default: () => null,
    },
  },
  setup(props, { emit }) {
    // eslint-disable-next-line no-unused-vars
    const { toastError, toastSuccess } = useToast()
    // const { changeSocialNetwork } = useAgenciesHandle()

    const getSocialAccounts = toRefs(props).socialAccounts

    const blankData = {
      url: '',
      type: '',
      isDel: false,
      isNew: true,
    }

    const dataToAdd = ref(null)

    function getDefaultData(data) {
      if (!data) return [cloneDeep(blankData)]
      return data.map(i => ({
        ...i, isDel: false, isNew: false,
      }))
    }

    const resetDataToEdit = () => {
      dataToAdd.value = getDefaultData(getSocialAccounts.value)
    }

    const { refFormObserver, getValidationState } = formValidation(
      resetDataToEdit,
    )

    function handleAddNewItem() {
      dataToAdd.value.push({
        ...blankData,
      })
    }

    function toggleItemDelete(index) {
      if ((!dataToAdd.value[index].type && !dataToAdd.value[index].url) || dataToAdd.value[index].isNew) {
        dataToAdd.value.splice(index, 1)
      } else {
        dataToAdd.value[index].isDel = !dataToAdd.value[index].isDel
      }
    }

    function submitAddSocialNetwork(bvModalEvent) {
      bvModalEvent.preventDefault()

      const validSocialAccounts = dataToAdd.value.filter(item => ((item.isNew && !item.isDel) || (!item.isNew && !item.isDel)) && (item.type && item.url))

      const socialAccountsPayload = validSocialAccounts.map(item => ({
        type: item.type,
        url: item.url,
      }))

      const payload = {
        socialAccounts: socialAccountsPayload,
      }

      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: '<p> Xác nhận thay đổi? </p>' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Xác nhận',
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            emit('update:socialAccounts', payload.socialAccounts)
            this.$bvModal.hide('modal-agency-add-social-network')
            // changeSocialNetwork(props.agenciesData.id, payload)
            //   .then(res => {
            //     emit('update:socialAccounts', res.socialAccounts)

            // this.$bvModal.hide('modal-agency-add-social-network')
            //   })
            //   .catch(() => {
            //     //
            //   })
            //   .finally(() => {
            //     this.$bvModal.hide('modal-api-loading')
            //   })
          }
        })
    }

    function handleModalOpen() {
      resetDataToEdit()
    }

    function handleModalClose() {
      //
    }

    return {
      submitAddSocialNetwork,
      dataToAdd,
      handleModalOpen,
      handleModalClose,
      refFormObserver,
      handleAddNewItem,
      getValidationState,
      toggleItemDelete,
    }
  },
}
</script>

import { useToast } from 'vue-toastification/composition'

import { TOPUP_APP_STORE_MODULE_NAME as TOPUP_STORE } from '@/constants/topup'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTopupHandle() {
  // Use toast
  const toast = useToast()

  const fetchBanks = async () => {
    try {
      await store.dispatch(`${TOPUP_STORE}/fetchBanks`)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching banks list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error.message,
        },
      })
    }
  }

  const fetchTopupQrCode = async payload => {
    try {
      await store.dispatch(`${TOPUP_STORE}/fetchTopupQrCode`, payload)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error getting top up QR code',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error.message,
        },
      })
    }
  }

  const confirmTopup = async payload => {
    try {
      await store.dispatch(`${TOPUP_STORE}/confirmTopup`, payload)
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error confirming top up',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error.message,
        },
      })
    }
  }

  return {
    fetchBanks,
    fetchTopupQrCode,
    confirmTopup,
  }
}

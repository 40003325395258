<template>
  <b-row class="mb-1">
    <!-- ANCHOR BE manager -->
    <b-col md="4">
      <b-form-group label-for="be-manager">
        <template #label>
          {{ $t('agency.BEManager') }}
          <!-- <span class="text-danger">(*)</span> -->
        </template>
        <v-select
          id="be-manager"
          v-model="BEManager"
          :options="agenciesManagerList.filter(i => i.type === 'BE')"
          :reduce="(val) => val"
          label="id"
          input-id="be-manager"
          :placeholder="$t('placeholderSelect')"
          :disabled="!canAccess('agency.assignManager')"
          searchable
          :clearable="false"
          :filterable="false"
          @open="openAgenciesManager"
          @search="searchAgenciesManager"
        >
          <template #selected-option="{firstName, lastName, type}">
            <div class="w-100 d-flex justify-content-between">
              <span class="font-weight-bold d-block text-nowrap">
                {{ lastName }} {{ firstName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(type)"
              >
                {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>
          <template #option="{firstName, lastName, type}">
            <div class="w-100 d-flex justify-content-between">
              <span class="font-weight-bold d-block text-nowrap">
                {{ lastName }} {{ firstName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(type)"
              >
                {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>
          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>
    </b-col>

    <!-- ANCHOR SE manager -->
    <b-col md="4">
      <b-form-group label-for="se-manager">
        <template #label>
          {{ $t('agency.SEManager') }}
          <!-- <span class="text-danger">(*)</span> -->
        </template>
        <v-select
          id="se-manager"
          v-model="SEManager"
          :options="agenciesManagerList.filter(i => i.type === 'SE')"
          :reduce="(val) => val"
          label="id"
          input-id="se-manager"
          :disabled="!canAccess('agency.assignManager')"
          :placeholder="$t('placeholderSelect')"
          searchable
          :clearable="false"
          :filterable="false"
          @open="openAgenciesManager"
          @search="searchAgenciesManager"
        >
          <template #selected-option="{firstName, lastName, type}">
            <div class="w-100 d-flex justify-content-between">
              <span class="font-weight-bold d-block text-nowrap">
                {{ lastName }} {{ firstName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(type)"
              >
                {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>
          <template #option="{firstName, lastName, type}">
            <div class="w-100 d-flex justify-content-between">
              <span class="font-weight-bold d-block text-nowrap">
                {{ lastName }} {{ firstName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(type)"
              >
                {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>
          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>
    </b-col>

    <!-- ANCHOR KTV manager -->
    <b-col md="4">
      <b-form-group label-for="ktv-manager">
        <template #label>
          {{ $t('agency.KTVManager') }}
          <!-- <span class="text-danger">(*)</span> -->
        </template>
        <v-select
          id="ktv-manager"
          v-model="KTVManager"
          :options="agenciesManagerList.filter(i => ['KTV', 'KTT'].includes(i.type) )"
          :reduce="(val) => val"
          label="id"
          input-id="ktv-manager"
          :placeholder="$t('placeholderSelect')"
          searchable
          :clearable="false"
          :filterable="false"
          :disabled="!canAccess('agency.assignManager')"
          @open="openAgenciesManager"
          @search="searchAgenciesManager"
        >
          <template #selected-option="{firstName, lastName, type}">
            <div class="w-100 d-flex justify-content-between">
              <span class="font-weight-bold d-block text-nowrap">
                {{ lastName }} {{ firstName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(type)"
              >
                {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>
          <template #option="{firstName, lastName, type}">
            <div class="w-100 d-flex justify-content-between">
              <span class="font-weight-bold d-block text-nowrap">
                {{ lastName }} {{ firstName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(type)"
              >
                {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>
          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol, BFormGroup, BRow, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'

import { resolveEmployeeTypeVariant, resolveEmployeeType } from '@/constants/selectOptions'

import useAgenciessHandle from '@agencies/useAgenciesHandle'

export default {
  components: {
    BCol,
    BFormGroup,
    BRow,
    BBadge,

    vSelect,
  },
  props: {},
  setup(props, { emit }) {
    const BEManager = ref()
    const SEManager = ref()
    const KTVManager = ref()

    function selectedItem(selectEmployee) {
      if (selectEmployee) {
        emit('select-employee', selectEmployee)
      }
      setTimeout(() => {
        BEManager.value = undefined
        SEManager.value = undefined
        KTVManager.value = undefined
      }, 0)
    }

    watch(BEManager, value => {
      selectedItem(value)
    })
    watch(SEManager, value => {
      selectedItem(value)
    })
    watch(KTVManager, value => {
      selectedItem(value)
    })

    const {
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
    } = useAgenciessHandle()

    return {
      BEManager,
      SEManager,
      KTVManager,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
      resolveEmployeeTypeVariant,
      resolveEmployeeType,
    }
  },
}
</script>

<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <b-modal
      id="modal-agencies-change-balance"
      :title="$t('agency.changeLimit')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      header-bg-variant="light-info"
      centered
      size="xl"
      no-close-on-backdrop
      @show="showHandle"
      @hide="hideModal"
    >
      <template #modal-footer="{cancel}">
        <div class="w-100 text-center">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0 ml-2"
            pill
            :disabled="hasOverWarningLimit || (hasMaximumCredit && isLimitF3BalanceAccordingF2Balance) || !isChangeLimitBalance"
            @click="submitChangeBalanceHandle"
          >
            <div class="d-flex-center px-25">
              {{ $t('reservation.confirmChange') }}
            </div>
          </b-button>
        </div>
      </template>

      <!-- SECTION note -->
      <b-card
        header-bg-variant="warning"
        header-class="px-1 py-50"
      >
        <template #header>
          <span class="text-dark font-weight-bolder">
            {{ $t('agency.note') }}
          </span>
        </template>
        <b-form-group class="mt-1 mb-0">
          <div class="d-flex-center flex-column flex-md-row gap-2">
            <b-form-textarea
              v-model="noteToEdit"
              lazy-formatter
              :formatter="trimInput"
              :disabled="isDisabledModifyNote"
              rows="2"
              max-rows="6"
              :placeholder="$t('reservation.notePlaceholder')"
            />
            <BButton
              :variant="isDisabledModifyNote ? 'flat-info' : 'flat-danger'"
              class="p-50"
              @click="() => isDisabledModifyNote = !isDisabledModifyNote"
            >
              <feather-icon
                v-if="isDisabledModifyNote"
                icon="EditIcon"
                size="20"
                class="m-50"
              />
              <feather-icon
                v-else
                icon="XIcon"
                size="20"
                class="m-50"
              />
            </BButton>
          </div>
        </b-form-group>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION credit limit -->
      <b-card
        header-bg-variant="warning"
        header-class="px-1 py-50"
      >
        <template #header>
          <span class="text-dark font-weight-bolder">
            {{ $t('agency.inputLimit') }}
          </span>
        </template>

        <b-row class="mx-0 mt-1">
          <!-- ANCHOR warningCredit -->
          <!-- <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-for="agency-warning-credit"
            >
              <template #label>
                <div>
                  <span>{{ $t('agency.labelWarningCredit') }}</span>
                  <p
                    v-if="isRoleF2 && meData"
                    class="d-inline mb-0"
                  >
                    (<span class="text-danger">Tối đa: {{ `< ${formatCurrency(meData.agency.limitCredit)}` }}</span>)
                  </p>
                </div>
              </template>
              <IAmInputMoney
                :value-money.sync="agenciesDataToEdit.warningCredit"
                class="flex-grow-1"
              />
            </b-form-group>
          </b-col> -->

          <!-- ANCHOR limitCredit  -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-for="agency-limit-credit"
              :label="$t('agency.labelLimitCredit')"
            >
              <template #label>
                <div>
                  <span>{{ $t('agency.labelLimitCredit') }}</span>
                  <p
                    v-if="isRoleF2 && meData && isLimitF3BalanceAccordingF2Balance"
                    class="d-inline mb-0"
                  >
                    (<span class="text-danger">Tối đa: {{ `< ${formatCurrency(meData.agency.limitCredit)}` }}</span>)
                  </p>
                </div>
              </template>

              <IAmInputMoney
                :value-money.sync="agenciesDataToEdit.limitCredit"
                class="flex-grow-1"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-row
          v-if="hasOverWarningLimit"
          class="text-danger"
        >
          <b-col class="ml-1">
            Giới hạn <span class="font-weight-bolder">cảnh báo tín dụng</span> phải thấp hơn giới hạn <span class="font-weight-bolder">số dư tín dụng</span>!
          </b-col>
        </b-row>

        <b-row
          v-if="hasMaximumCredit"
          class="text-danger"
        >
          <b-col class="ml-1">
            Giới hạn <span class="font-weight-bolder">số dư tín dụng</span> phải thấp hơn <span class="font-weight-bolder">tối đa tín dụng!</span>
          </b-col>
        </b-row> -->
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION audit log -->
      <AppCollapse type="border card">
        <AppCollapseItem
          title
          class-header="card-header bg-warning"
        >
          <template #header>
            <span class="text-dark font-weight-bolder">
              {{ $t('agency.auditLog') }}
            </span>
          </template>
          <b-table-lite
            bordered
            responsive
            :sticky-header="true"
            show-empty
            :empty-text="$t('noMatchingResult')"
            no-border-collapse
            small
            class="mb-1 rounded"
            thead-class="text-dark font-weight-bolder text-nowrap"
            :items="agenciesDataToEdit.auditLogsCredit"
            :fields="tableColumns"
          >
            <template
              v-for="column in tableColumns"
              #[`head(${column})`]="data"
            >
              <span
                :key="column"
                class="text-dark"
              >
                {{ $t(`agency.auditLogsCredit.${data.column}`) }}
              </span>
            </template>
            <template #cell(no)="data">
              {{ agenciesDataToEdit.auditLogsCredit.length - Number(data.index) }}
            </template>

            <template #cell(updated)="{item}">
              <div>
                {{ convertISODateTime(item.updatedAt).dateTime }}
                <div>
                  {{ $t('by') }} <span class="font-weight-bolder">{{ item.modifiedBy.username }}</span>
                </div>
              </div>
            </template>
            <template #cell(limitCredit)="{item}">
              <div>
                <div>
                  <span class="text-body-3"><em>Trước khi sửa đổi:</em></span>
                  <div class="font-weight-bold text-right">
                    {{ formatCurrency(JSON.parse(item.oldValue).limitCredit ?? JSON.parse(item.oldValue)) }}
                  </div>
                </div>
                <div>
                  <span class="text-body-3"><em>Sau khi sửa đổi:</em></span>
                  <div class="font-weight-bold text-right">
                    {{ formatCurrency(JSON.parse(item.newValue).limitCredit ?? JSON.parse(item.newValue)) }}
                  </div>
                </div>
              </div>
            </template>

            <template #cell(warningCredit)="{item}">
              <div>
                <div>
                  <span class="text-body-3"><em>Trước khi sửa đổi:</em></span>
                  <div class="font-weight-bold text-right">
                    {{ formatCurrency(JSON.parse(item.oldValue).warningCredit ?? JSON.parse(item.oldValue)) }}
                  </div>
                </div>
                <div>
                  <span class="text-body-3"><em>Sau khi sửa đổi:</em></span>
                  <div class="font-weight-bold text-right">
                    {{ formatCurrency(JSON.parse(item.newValue).warningCredit ?? JSON.parse(item.newValue)) }}
                  </div>
                </div>
              </div>
            </template>

            <template #cell(note)="{item}">
              <div style="min-width: 140px">
                <div>
                  <span class="text-body-3"><em>Trước khi sửa đổi:</em></span>
                  <div class="font-weight-bold ">
                    {{ JSON.parse(item.oldValue).note || 'N/A' }}
                  </div>
                </div>
                <div>
                  <span class="text-body-3"><em>Sau khi sửa đổi:</em></span>
                  <div class="font-weight-bold ">
                    {{ JSON.parse(item.newValue).note || 'N/A' }}
                  </div>
                </div>
              </div>
            </template>
          </b-table-lite>
        </AppCollapseItem>
      </AppCollapse>
      <!-- !SECTION -->
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormTextarea,
  BTableLite,
  VBTooltip,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import { apiAgencies } from '@/api/'
import env from '@/libs/env'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { integer, agencyWarningCredit, agencyLimitCredit } from '@validations'
import useToast from '@useToast'

export default {
  components: {
    BModal,
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BTableLite,
    AppCollapse,
    AppCollapseItem,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    agenciesData: {
      type: Object,
      default: () => {},
    },
    agenciesDataRaw: {
      type: Object,
      default: () => {},
    },
    meData: {
      type: Object,
      default: () => {},
    },
    limitCredit: {
      type: Number,
      default: () => 0,
    },
    warningCredit: {
      type: Number,
      default: () => 0,
    },
    isRoleF2: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { toastError, toastSuccess } = useToast()
    const { isRoleF2, meData } = toRefs(props)

    const agenciesDataToEdit = ref(cloneDeep(props.agenciesData))

    const showModal = ref(false)
    const hasOverWarningLimit = ref(false)
    const hasMaximumCredit = ref(false)
    const isDisabledModifyNote = ref(true)
    const noteToEdit = ref(agenciesDataToEdit.value.note)

    const resetAgenciesDataToEdit = () => {
      agenciesDataToEdit.value = cloneDeep(props.agenciesData)
    }
    const { refFormObserver, getValidationState } = formValidation(
      resetAgenciesDataToEdit,
    )
    const { isLimitF3BalanceAccordingF2Balance } = env

    watch(
      [
        () => showModal.value,
        () => agenciesDataToEdit.value.warningCredit,
        () => agenciesDataToEdit.value.limitCredit,
        () => meData.value,
      ],
      ([show, warning, limit, meData]) => {
        if (show) {
          agenciesDataToEdit.value.warningCredit = agenciesDataToEdit.value.limitCredit
          const warningExceedsLimit = Number(warning) > Number(limit)
          hasOverWarningLimit.value = warningExceedsLimit

          if (isRoleF2.value && meData) {
            const agencyLimitCredit = Number(meData.agency.limitCredit)

            if (agencyLimitCredit !== 0) {
              hasMaximumCredit.value = Number(limit) > agencyLimitCredit
            } else if (agencyLimitCredit === 0) {
              hasMaximumCredit.value = Number(limit) > agencyLimitCredit
            }
          }
        }
      }, { deep: true },
    )

    function showHandle() { // SHOW
      resetAgenciesDataToEdit()
      isDisabledModifyNote.value = true
      showModal.value = true
    }

    function hideModal() { // HIDE
      showModal.value = false
    }

    function submitChangeBalanceHandle() { // SUBMIT PAYLOAD
      const payload = {
        warningCredit: Number(agenciesDataToEdit.value.warningCredit),
        limitCredit: Number(agenciesDataToEdit.value.limitCredit),
        note: noteToEdit.value,
      }

      if (payload.limitCredit < payload.warningCredit) {
        toastError({
          title: 'Giới hạn cảnh báo phải thấp hơn Quỹ đại lý.',
        })
        return
      }

      this.$bvModal.show('modal-api-loading')
      apiAgencies.updateCredit(agenciesDataToEdit.value.id, payload)
        .then(res => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })

          emit('update:limit-credit', res.limitCredit)
          emit('update:warning-credit', res.warningCredit)
          emit('refetchAgency')
          this.$bvModal.hide('modal-agencies-change-balance')
        })
        .catch(e => {
          toastError({
            title: 'messagesList.error',
            content: `${e.message || e}`,
          })

          console.error(e)
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    watch(() => isDisabledModifyNote.value, value => {
      if (value) {
        noteToEdit.value = agenciesDataToEdit.value.note
      }
    })

    const tableColumns = ref([
      'no',
      'updated',
      'limitCredit',
      // 'warningCredit',
      'note'])

    const isChangeLimitBalance = computed(() => agenciesDataToEdit.value.limitCredit !== props.agenciesDataRaw.limitCredit)
    return {
      agenciesDataToEdit,
      showHandle,
      hideModal,
      submitChangeBalanceHandle,
      hasOverWarningLimit,
      hasMaximumCredit,

      formatCurrency,

      refFormObserver,
      getValidationState,

      integer,
      agencyWarningCredit,
      agencyLimitCredit,
      trimInput,
      isDisabledModifyNote,
      noteToEdit,
      tableColumns,
      convertISODateTime,
      isChangeLimitBalance,
      isLimitF3BalanceAccordingF2Balance,
    }
  },
}
</script>

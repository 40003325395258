var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-topup-modal",
      "size": "lg",
      "centered": "",
      "header-bg-variant": "light-info",
      "title": _vm.$t('agency.topup'),
      "ok-title": _vm.$t('agency.generateQRcode'),
      "cancel-title": _vm.$t('cancel'),
      "ok-disabled": !!_vm.qrCodeImgUrl
    },
    on: {
      "show": function show($event) {
        return _vm.$emit('show', $event);
      },
      "ok": _vm.handleGenerateQRcode
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.recipientBank)
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.bankName')
    }
  }, [_c('v-select', {
    attrs: {
      "label": "bankAccountNo",
      "options": _vm.recipientBanksList,
      "clearable": false
    },
    on: {
      "input": _vm.handleChangeInput
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s("".concat(data.bankAccountName, " - ").concat(data.bankAccountNo, " - ").concat(data.bankCode, " - ").concat(data.branch)) + " ")];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s("".concat(data.bankAccountName, " - ").concat(data.bankAccountNo, " - ").concat(data.bankCode, " - ").concat(data.branch)) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.recipientBank,
      callback: function callback($$v) {
        _vm.recipientBank = $$v;
      },
      expression: "recipientBank"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.accNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.recipientAccNumber,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.accHolder')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.recipientBankAccountName,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.branch')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.recipientBankBranch,
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.amountNumber ? _c('b-form-group', {
    attrs: {
      "label": _vm.$t('agency.amountNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.formatCurrency(_vm.amountNumber),
      "readonly": ""
    }
  })], 1) : _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('agency.amountNumber')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "rules": {
        required: true,
        max: _vm.MAX_LENGTH.paymentAmount
      },
      "name": _vm.$t('agency.amountNumber')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('b-input-group-text', {
                class: {
                  'border-danger': errors[0]
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.currency')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "name": _vm.$t('agency.amountNumber'),
            "value": _vm.paymentAmount,
            "state": errors[0] ? false : null,
            "placeholder": _vm.$t('agency.amountNumber')
          }
        }), _c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          attrs: {
            "value-money": _vm.paymentAmount
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              _vm.paymentAmount = $event;
            },
            "update:value-money": function updateValueMoney($event) {
              _vm.paymentAmount = $event;
            }
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_vm.qrCodeImgUrl ? _c('b-img', {
    staticStyle: {
      "max-height": "400px"
    },
    attrs: {
      "src": _vm.qrCodeImgUrl,
      "fluid": "",
      "center": "",
      "alt": "QR Code"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="">
  <b-card
    id="agencies-edit-company"
    header-tag="header"
    border-variant="info"
    header-bg-variant="light-info"
    header-class="py-1"
    class="border mb-0 mt-1"
    no-body
  >
    <template #header>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h5 class="m-0">
          {{ $t('agency.company.headerInfo') }}
        </h5>
      </div>
    </template>
    <b-card-body class="p-1">
      <IAmOverlay :loading="loading || false">
        <validation-observer
          ref="refFormObserver"
          #default="{ pristine }"
        >
          <BAlert
            v-if="!haveCompany"
            show
            variant="warning"
            class="p-1"
          >
            {{ $t('agency.company.noCompany') }}
          </BAlert>
          <b-row>
            <b-col
              v-for="({ key , required: isRequired, disabled: isDisabled, cannotInput, formatterInput, validate}) in fields"
              :key="key"
              md="6"
              :class="['updatedAt', 'createdAt'].includes(key) ? 'order-1' : ''"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`agency.company.field.${key}`)"
                :vid="key"
                :rules="{required: isRequired, email: validate === 'email'}"
              >
                <b-form-group>
                  <div class="d-flex gap-1">
                    <label :for="key">{{ $t(`agency.company.field.${key}`) }}</label>
                    <span
                      v-if="isRequired"
                      class="text-danger"
                    >(*)</span>
                  </div>
                  <div v-if="cannotInput">
                    {{ formatterInput ? formatterInput(agencyCompanyData[key]) : agencyCompanyData[key] }}
                  </div>
                  <div v-else-if="key === 'generateInvoiceNumberOnCreation'">
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="agencyCompanyData[key]"
                      :disabled="isDisabledInput || isDisabled"
                      class="text-dark"
                      :options="[
                        { text: $t('agency.company.field.onCreation'), value: true },
                        { text: $t('agency.company.field.onRelease'), value: false },
                      ]"
                      :name="$t(`agency.company.field.${key}`)"
                    />
                  </div>
                  <b-form-input
                    v-else
                    :id="key"
                    v-model="agencyCompanyData[key]"
                    v-remove-non-numeric-chars.allNumber="validate === 'number'"
                    :state="errors.length > 0 ? false : null"
                    class="form-control"
                    :disabled="isDisabledInput || isDisabled"
                    :name="$t(`agency.company.field.${key}`)"
                    :placeholder="$t(`agency.company.ph.${key}`)"
                  />
                  <BFormInvalidFeedback class="text-danger">
                    {{ errors[0] }}
                  </BFormInvalidFeedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              class="d-flex gap-1 order-2"
            >
              <b-button
                v-if="!haveCompany"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="createHandle"
              >
                {{ $t('create') }}
              </b-button>
              <b-button
                v-if="haveCompany && isEdit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="updateHandle"
              >
                {{ $t('saveChanges') }}
              </b-button>
              <b-button
                v-if="isDisabledInput"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="isEdit = true"
              >
                {{ $t('edit') }}
              </b-button>
              <b-button
                v-if="haveCompany && isEdit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                @click="confirmCancelEdit(pristine)"
              >
                {{ $t('cancelEdit') }}
              </b-button>
            </b-col>
            <b-col
              cols="6"
              class="d-flex justify-content-end gap-1 order-2"
            >
              <b-button
                v-if="haveCompany"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="agencyCompany.active ? 'danger' : 'info'"
                :disabled="!agencyCompany.active && !isRoleF1"
                @click="toggleActiveCompanyHandle"
              >
                {{ agencyCompany.active
                  ? $t('agency.company.deactiveCompany')
                  : $t('agency.company.activeCompany') }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </IAmOverlay>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  computed, toRefs, ref, watch,
} from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BAlert,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import isUndefined from 'lodash/isUndefined'

import { convertISODateTime } from '@/@core/utils/filter'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'

import useAgenciesHandle from '@agencies/useAgenciesHandle'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BAlert,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    agencyCompany: {
      type: [Object, null],
      default: null,
    },
    agencyId: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      createCompany, updateCompany, loading, toggleActiveCompany,
    } = useAgenciesHandle()
    const { refFormObserver } = formValidation()
    const { agencyCompany: agencyCompanyProp, agencyId } = toRefs(props)

    const haveCompany = computed(() => !isEmpty(agencyCompanyProp.value))
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isEdit = ref(false)

    const blankAgencyCompany = {
      name: '',
      taxCode: '',
      province: '',
      district: '',
      ward: '',
      address: '',
      phoneNumber: '',
      email: '',
      taxAuthorityCode: '',
      taxAuthorityName: '',
      agentName: '',
      agentPhoneNumber: '',
      active: true,
      generateInvoiceNumberOnCreation: true,
    }

    const agencyCompanyData = ref()
    const resetAgencyCompanyData = () => {
      if (agencyCompanyProp.value && isUndefined(agencyCompanyProp.value?.generateInvoiceNumberOnCreation)) {
        agencyCompanyProp.value.generateInvoiceNumberOnCreation = true
      }
      agencyCompanyData.value = cloneDeep(haveCompany.value ? agencyCompanyProp.value : blankAgencyCompany)
    }

    watch(agencyCompanyProp, () => { resetAgencyCompanyData() }, { deep: true, immediate: true })

    async function createHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      await createCompany(agencyId.value, agencyCompanyData.value)
      emit('refetchAgency')
    }

    async function updateHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      await updateCompany(agencyId.value, agencyCompanyData.value)
      emit('refetchAgency')
      isEdit.value = false
    }

    function confirmCancelEdit(pristine = true) {
      if (!pristine) {
        this.$bvModal
          .msgBoxConfirm(this.$t('modal.confirmCancelEdit'), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('modal.iamConfirm'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              isEdit.value = false
              agencyCompanyData.value = cloneDeep(agencyCompanyProp.value)
            }
          })
      } else {
        isEdit.value = false
        agencyCompanyData.value = cloneDeep(agencyCompanyProp.value)
      }
    }

    const isDisabledInput = computed(() => haveCompany.value && !isEdit.value)
    const fields = computed(() => {
      const result = Object.keys(blankAgencyCompany)
        .filter(f => f !== 'active')
        .map(f => ({
          key: f,
          required: !['agentName', 'agentPhoneNumber', 'generateInvoiceNumberOnCreation'].includes(f),
          validate:
          ['agentPhoneNumber', 'phoneNumber', 'taxAuthorityCode', 'taxCode'].includes(f)
            ? 'number'
            : ['email'].includes(f) ? 'email' : null,
          disabled: isEdit.value && ['taxCode', 'email'].includes(f),
        }))

      if (haveCompany.value) {
        result.push({
          key: 'createdAt', required: false, cannotInput: true, formatterInput: val => convertISODateTime(val).dateTime,
        })
        result.push({
          key: 'updatedAt', required: false, cannotInput: true, formatterInput: val => convertISODateTime(val).dateTime,
        })
      }
      return result
    })

    async function toggleActiveCompanyHandle() {
      const payload = {
        active: !agencyCompanyProp.value.active,
      }
      await toggleActiveCompany(agencyCompanyProp.value.id, payload)
      emit('refetchAgency')
    }
    return {
      isRoleF1,
      haveCompany,
      agencyCompanyData,
      refFormObserver,
      createHandle,
      convertISODateTime,
      isEdit,
      updateHandle,
      confirmCancelEdit,
      isDisabledInput,
      fields,
      loading,
      toggleActiveCompanyHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
#agencies-edit-company ::v-deep {
  .custom-control-label {
    color: black !important;
  }
}
</style>

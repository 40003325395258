import { apiSaleReports } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchListAccountsPayableF2(ctx, { agencyId, params }) {
      return apiSaleReports.fetchListAccountsPayableF2(agencyId, params)
    },
    fetchListAccountsPayableF3(ctx, { agencyId, params }) {
      return apiSaleReports.fetchListAccountsPayableF3(agencyId, params)
    },
    fetchDetailAccountsPayableF2(ctx, { agencyId, params }) {
      return apiSaleReports.fetchDetailAccountsPayableF2(agencyId, params)
    },
    exportListAccountsPayableF2(ctx, { agencyId, params }) {
      return apiSaleReports.exportListAccountsPayableF2(agencyId, params)
    },
    exportDetailAccountsPayableF2(ctx, { agencyId, params }) {
      return apiSaleReports.exportDetailAccountsPayableF2(agencyId, params)
    },
  },
}

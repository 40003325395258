<template>
  <b-modal
    id="id-modal-agency-change-password-employee-account"
    :title="$t('agency.employees.changePassword')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="p-1"
    centered
    no-close-on-backdrop
    @hide="hideModal"
  >
    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-danger"
        pill
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient px-1 px-md-2"
        pill
        :disabled="!passwordState"
        @click="handleConfirmChangePassword()"
      >
        {{ $t('reservation.confirm') }}
      </b-button>
    </template>

    <div class="mb-50">
      {{ $t('agency.employees.changePasswordTitle') }}
    </div>

    <div
      v-if="employees"
      class="d-flex flex-column"
    >
      <div>
        <BFormInput
          id="input-password"
          v-model="newPassword"
          class="mt-75"
          :state="passwordState"
          aria-describedby="input-password-feedback"
          :placeholder="$t('flight.placeholderInput')"
        />
        <b-form-invalid-feedback id="input-password-feedback">
          {{ $t('agency.employees.min6character') }}
        </b-form-invalid-feedback>
      </div>
      <div class="text-center mt-50">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-warning rounded-lg"
          size="sm"
          @click="handleGenerateNewPassword"
        >
          <feather-icon
            class="mr-25"
            icon="RepeatIcon"
          />
          Tạo ngẫu nhiên
        </b-button>
      </div>
    </div>

    <div v-else>
      <b-spinner variant="info" />
    </div>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  BModal,
  BButton,
  BFormInput,
  BSpinner,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
// import { faker } from '@faker-js/faker'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

import { apiEmployee } from '@/api/'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BSpinner,
    BFormInvalidFeedback,

  },
  props: {
    employees: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const { toastWarning, toastError, toastSuccess } = useToast()

    const newPassword = ref('')
    const passwordState = computed(() => newPassword.value?.length > 5)

    function hideModal() {
      newPassword.value = ''
      emit('update:employees', null)
    }

    function handleConfirmChangePassword() {
      if (isEmpty(newPassword.value)) {
        toastWarning({
          title: 'messagesList.error',
          content: 'agency.employees.plsInputPassword',
        })
        return
      }

      const id = props.employees.id
      const req = { newPassword: newPassword.value }

      this.$bvModal.show('modal-api-loading')

      apiEmployee.regeneratePassword(id, req)
        .then(() => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'agency.employees.changePasswordSuccess',
          })

          Vue.swal({
            title: 'Đặt lại mật khẩu thành công!',
            html: `
                    <div>
                      Mật khẩu mới:  <span class="fw-700 font-medium-3">${newPassword.value}</span>
                    </div>
                  `,
            icon: 'success',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Copy',
            customClass: {
              confirmButton: 'btn btn-warning',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              navigator.clipboard.writeText(newPassword.value)
              Vue.swal({
                icon: 'success',
                title: 'Đã copy!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
            this.$bvModal.hide('id-modal-agency-change-password-employee-account')
          })

          emit('refetchAgency')
        })
        .catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'agency.employees.changePasswordError',
          })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    // function handleGenerateNewPassword() { // NOTE: fakerjs -> devDep
    //   const password = faker.internet.password({
    //     length: 6,
    //     memorable: false,
    //     pattern: /[a-zA-Z0-9]/,
    //     prefix: 'A',
    //   })
    //   newPassword.value = password
    // }

    function handleGenerateNewPassword() {
      const uuidString = uuidv4().replace(/-/g, '')
      let firstLetter = ''
      for (const char of uuidString) {
        if (/[a-f]/.test(char)) {
          firstLetter = char.toUpperCase()
          break
        }
      }
      if (!firstLetter) {
        firstLetter = 'A'
      }
      const restOfPassword = uuidString.slice(1, 6)

      newPassword.value = firstLetter + restOfPassword
    }

    return {
      newPassword,
      passwordState,
      hideModal,
      handleConfirmChangePassword,
      handleGenerateNewPassword,
    }
  },
}
</script>

<template>
  <div>
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      class="border mb-0 mt-2"
      no-body
    >
      <template #header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="m-0">
            {{ $t('agency.managerInfo') }}
          </h5>
          <div>
            <b-button
              variant="info"
              class="ml-1"
              :disabled="!canAccess('agency.assignManager') || !isDirty"
              @click="saveHandle"
            >
              <span class="">{{ $t('employee.save') }}</span>
            </b-button>
          </div>
        </div>
      </template>
      <b-card-body class="p-2 pt-2">
        <!-- Filters -->
        <AgenciesEditSearchAgencies @select-employee="addToList" />
        <!-- ANCHOR Table Content -->
        <b-table
          ref="refEmployeesListTable"
          class="rounded"
          bordered
          responsive
          show-empty
          :empty-text="$t('noMatchingResult')"
          :items="managerEmployeesItems"
          :fields="tableColumns"
          primary-key="id"
        >
          <!-- ANCHOR header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`agency.managerEmployeesTable.${data.label}`) }}
            </span>
          </template>
          <template #cell(employeeCode)="data">
            <b-link
              :to="{name: 'apps-employees-edit', params: { id: data.item.id }}"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
            >
              {{ data.item.employeeCode }}
            </b-link>
          </template>

          <template #cell(employeeName)="data">
            <div class="d-flex justify-content-between">
              <span>
                {{ data.item.firstName }} {{ data.item.lastName }}
              </span>
              <b-badge
                class="line-height-condensed mx-1"
                :variant="resolveEmployeeTypeVariant(data.item.type)"
              >
                {{ $t(resolveEmployeeType(data.item.type, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>

          <!-- ANCHOR Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap text-right">
              <b-button
                class="py-50 px-1"
                variant="flat-danger"
                @click="removeItemToList(data.item.id)"
              >
                <feather-icon
                  :id="`invoice-row-${data.item.id}-trash-icon`"
                  icon="TrashIcon"
                  class="cursor-pointer"
                  size="16"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BLink, BButton, BBadge,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import { resolveEmployeeTypeVariant, resolveEmployeeType } from '@/constants/selectOptions'
import router from '@/router'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AgenciesEditSearchAgencies from '@agencies/agencies-edit/AgenciesEditSearchAgencies.vue'

export default {
  components: {
    AgenciesEditSearchAgencies,
    BCard,
    BCardBody,
    BTable,
    BLink,
    BButton,
    BBadge,
  },
  props: {
    managerEmployees: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const toast = useToast()
    const isDirty = ref(false)

    const tableColumns = [
      { label: 'employeeCode', key: 'employeeCode' },
      { label: 'employeeName', key: 'employeeName' },
      { label: 'emailAddress', key: 'emailAddress' },
      { label: 'phoneNumber', key: 'phoneNumber' },
      { label: 'actions', key: 'actions' },
    ]

    const managerEmployeesItems = ref(cloneDeep(props.managerEmployees))

    const selectMode = 'range'
    const selected = []

    function addToList(selectEmployee) {
      let checkDuplicate = false
      managerEmployeesItems.value.forEach(employee => {
        if (employee.employeeCode === selectEmployee.employeeCode) {
          checkDuplicate = true
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('messagesList.addAgencyToListManagementDuplicated'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      if (!checkDuplicate) {
        managerEmployeesItems.value.push(selectEmployee)
        isDirty.value = true
      }
    }

    function removeItemToList(id) {
      const index = managerEmployeesItems.value.findIndex(agc => agc.id === id)
      if (index > -1) {
        managerEmployeesItems.value.splice(index, 1)
        isDirty.value = true
      }
    }

    function saveHandle() {
      this.$bvModal.show('modal-api-loading')
      const payload = {
        employeeIds: managerEmployeesItems.value.map(item => item.id),
      }
      store.dispatch('app-agencies/assignManagers', { id: Number(router.currentRoute.params.id), payload })
        .then(() => {
          isDirty.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('agency.assignManagersSuccess'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('agency.assignManagersError'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.response.data.message || e,
            },
          })
        })
        .finally(() => { this.$bvModal.hide('modal-api-loading') })
    }

    return {
      tableColumns,
      selectMode,
      selected,
      addToList,
      removeItemToList,
      saveHandle,
      managerEmployeesItems,
      isDirty,

      resolveEmployeeTypeVariant,
      resolveEmployeeType,
    }
  },
}
</script>
